import React, { useState } from 'react';
import {
    Card,
    CardContent,
    Typography,
    IconButton,
    Grid,
    Menu,
    MenuItem,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useNavigate } from 'react-router';

const NotificationCard = ({ notification, onDelete }) => {
    const { title, message, time, frequency, type } = notification;
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = useState(null);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false); // State to track whether the card is disabled or not

    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleEdit = () => {
        navigate('/notification/edit-notification', {
            state: { notification, status: 'edit' }
        });
        handleMenuClose();
    };

    const handleDeleteClick = () => {
        setOpenDeleteModal(true);
        handleMenuClose();
    };

    const handleDeleteConfirm = () => {
        onDelete(notification.id);
        setOpenDeleteModal(false);
    };

    const handleDeleteCancel = () => {
        setOpenDeleteModal(false);
    };

    const handleDisableToggle = () => {
        setIsDisabled(!isDisabled);
        handleMenuClose();
    };

    return (
        <Card
            elevation={3}
            sx={{
                height: '100%',
                position: 'relative',
                // backgroundColor: isDisabled ? 'white' : 'white', // Lighter background if disabled
                transition: 'background-color 0.3s ease',
                filter: isDisabled ? 'blur(1px)' : 'none', // Apply blur when disabled
                opacity: isDisabled ? 0.4 : 1 // Lower opacity when disabled
            }}
        >
            <CardContent>
                <Typography variant="h4" component="div" gutterBottom sx={{ textAlign: 'center', mb: 2 }}>
                    {title}
                </Typography>
                <Typography variant="body1" color="textSecondary" paragraph>
                    {message.length > 100 ? `${message.substring(0, 100)}...` : message}
                </Typography>
                <Grid container spacing={1} justifyContent="space-between">
                    <Grid item>
                        <Typography variant="body2" color="textSecondary">
                            <strong>Appears in:</strong> {time} days
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="body2" color="textSecondary">
                            <strong>Repeats:</strong> Every {frequency} days
                        </Typography>
                    </Grid>
                </Grid>
                <Typography variant="body2" color="textSecondary" sx={{ mt: 2 }}>
                    <strong>Type:</strong> {type}
                </Typography>
            </CardContent>
            <IconButton
                aria-label="more"
                onClick={handleMenuClick}
                sx={{
                    position: 'absolute',
                    top: '8px',
                    right: '8px',
                    color: 'rgba(0, 0, 0, 0.54)'
                }}
            >
                <MoreVertIcon />
            </IconButton>
            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
                <MenuItem onClick={handleEdit}>Edit</MenuItem>
                <MenuItem onClick={handleDisableToggle}>{isDisabled ? 'Enable' : 'Disable'}</MenuItem>
                <MenuItem onClick={handleDeleteClick}>Delete</MenuItem>
            </Menu>

            <Dialog
                open={openDeleteModal}
                onClose={handleDeleteCancel}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{'Confirm Delete'}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">Are you sure you want to delete this notification?</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDeleteCancel} color="primary">
                        No
                    </Button>
                    <Button onClick={handleDeleteConfirm} color="primary" autoFocus>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
        </Card>
    );
};

export default NotificationCard;
