import PropTypes from "prop-types";
import {
  Box,
  CardContent,
  Divider,
  Grid,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import Avatar from "ui-component/extended/Avatar";
import SubCard from "ui-component/cards/SubCard";
import MailTwoToneIcon from "@mui/icons-material/MailTwoTone";
import PhonelinkRingTwoToneIcon from "@mui/icons-material/PhonelinkRingTwoTone";
import PinDropTwoToneIcon from "@mui/icons-material/PinDropTwoTone";
import FriendsListings from "ui-component/graphs/FriendsListings";

const createData = (name, calories, fat) => ({ name, calories, fat });

const Profile = ({ userInfo }) => {
  const rows = [
    createData("Full Name", ":", userInfo?.fullName),
    // createData("Address", ":", userInfo?.location ? userInfo.location : "N/A"),
    // createData("Phone", ":", userInfo?.phoneNumber),
    // createData("Email", ":", userInfo?.email),
    // createData("Friends", ":", userInfo?.friendsCount),
    createData(
      "Hobbies",
      ":",
      userInfo?.hobbies ? userInfo.hobbies.join(", ") : "N/A"
    ),
  ];

  return (
    <Grid container spacing={2}>
      <Grid item lg={4} xs={12}>
        <SubCard
          title={
            <Grid container spacing={2} alignItems="center">
              <Grid item>
                <Avatar alt="User 1" src={userInfo?.avatar} />
              </Grid>
              <Grid item xs zeroMinWidth>
                <Typography align="left" variant="subtitle1">
                  {userInfo?.userName}
                </Typography>
                <Typography align="left" variant="subtitle2">
                  Gamer
                </Typography>
              </Grid>
            </Grid>
          }
        >
          <List component="nav" aria-label="main mailbox folders">
            <ListItemButton>
              <ListItemIcon>
                <MailTwoToneIcon sx={{ fontSize: "1.3rem" }} />
              </ListItemIcon>
              <ListItemText
                primary={<Typography variant="subtitle1">Email</Typography>}
              />
              <ListItemSecondaryAction>
                <Typography variant="subtitle2" align="right">
                  {userInfo?.email}
                </Typography>
              </ListItemSecondaryAction>
            </ListItemButton>
            <Divider />
            <ListItemButton>
              <ListItemIcon>
                <PhonelinkRingTwoToneIcon sx={{ fontSize: "1.3rem" }} />
              </ListItemIcon>
              <ListItemText
                primary={<Typography variant="subtitle1">Phone</Typography>}
              />
              <ListItemSecondaryAction>
                <Typography variant="subtitle2" align="right">
                  {userInfo?.phoneNumber}
                </Typography>
              </ListItemSecondaryAction>
            </ListItemButton>
            <Divider />
            <ListItemButton>
              <ListItemIcon>
                <PinDropTwoToneIcon sx={{ fontSize: "1.5rem" }} />
              </ListItemIcon>
              <ListItemText
                primary={<Typography variant="subtitle1">Location</Typography>}
              />
              <ListItemSecondaryAction>
                <Typography variant="subtitle2" align="right">
                  {userInfo?.location ? userInfo.location : "N/A"}
                </Typography>
              </ListItemSecondaryAction>
            </ListItemButton>
          </List>
          <CardContent>
            <Grid container spacing={0} justifyContent="center">
              <Grid item xs={4}>
                <Typography align="center" variant="h3">
                  {userInfo?.friendsCount ? userInfo.friendsCount : "0"}
                </Typography>
                <Typography align="center" variant="subtitle2">
                  Friends
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography align="center" variant="h3">
                  {userInfo?.points ? userInfo.points : "0"}
                </Typography>
                <Typography align="center" variant="subtitle2">
                  Points
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
        </SubCard>
      </Grid>
      <Grid item lg={8} xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <SubCard title="About me">
              <Grid container spacing={2} style={{ height: "155px" }}>
                <Grid item xs={12}>
                  <Typography variant="subtitle1">Personal Details</Typography>
                </Grid>
                <Divider sx={{ pt: 1 }} />
                <Grid item xs={12}>
                  <TableContainer>
                    <Table
                      sx={{
                        "& td": {
                          borderBottom: "none",
                        },
                      }}
                      size="small"
                    >
                      <TableBody>
                        {rows.map((row) => (
                          <TableRow key={row.name}>
                            <TableCell variant="head">{row.name}</TableCell>
                            <TableCell>{row.calories}</TableCell>
                            <TableCell>{row.fat}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
            </SubCard>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <FriendsListings friends={userInfo?.friends} />
      </Grid>
    </Grid>
  );
};

Profile.propTypes = {
  userInfo: PropTypes.object.isRequired,
};

export default Profile;
