import React from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const LineChart = ({ labels, datasets }) => {
    const data = {
        labels: labels,
        datasets: datasets.map((dataset) => ({
            ...dataset,
            fill: true,
            tension: 0.1
        }))
    };

    const options = {
        maintainAspectRatio: false,
        plugins: {
            datalabels: {
                align: 'end', // Aligns the label to be above the dot
                anchor: 'end', // Anchor the label to the end of the line
                offset: 0, // Increase this value to move the labels further away from the dot
                font: {
                    weight: 'bold',
                    size: 12
                },
                color: '#000', // Label color
                formatter: function (value) {
                    return value; // Shows the value on the point
                }
            }
        }
    };

    return (
        <div
            style={{ width: '100%', height: '450px', backgroundColor: 'white', padding: '20px', borderRadius: '8px', fontFamily: 'Roboto' }}
        >
            <Line data={data} options={options} />
        </div>
    );
};

export default LineChart;
