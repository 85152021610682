import { lazy } from "react";

// project imports
import Loadable from "ui-component/Loadable";
import MinimalLayout from "layout/MinimalLayout";
import NonProtectedRoute from "./NonProtectedRoutes";

// login option 3 routing
const AuthLogin3 = Loadable(
  lazy(() => import("views/pages/authentication/authentication3/Login3"))
);
const AuthVerifyOtp3 = Loadable(
  lazy(() => import("views/pages/authentication/authentication3/VerifyOtp3"))
);
const AuthRegister3 = Loadable(
  lazy(() => import("views/pages/authentication/authentication3/Register3"))
);

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const AuthenticationRoutes = {
  path: "/",
  element: (
    <NonProtectedRoute>
      <MinimalLayout />
    </NonProtectedRoute>
  ),
  children: [
    {
      path: "/login",
      element: <AuthLogin3 />,
    },
    {
      path: "/verify-otp",
      element: <AuthVerifyOtp3 />,
    },
    // ,
    // {
    //     path: '/register',
    //     element: <AuthRegister3 />
    // }
  ],
};

export default AuthenticationRoutes;
