// assets
import { IconBrandChrome, IconHelp, IconUser } from "@tabler/icons";
import CribOutlinedIcon from "@mui/icons-material/CribOutlined";
import StoreMallDirectoryOutlinedIcon from "@mui/icons-material/StoreMallDirectoryOutlined";
// constant
const icons = { IconBrandChrome, IconHelp, IconUser };

// ==============================|| USER PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const branch = {
  id: "user-docs-roadmap-2",
  type: "group",
  children: [
    {
      id: "branch",
      title: "Branches",
      type: "item",
      url: "/branch",
      icon: StoreMallDirectoryOutlinedIcon,
      breadcrumbs: false,
    },
  ],
};

export default branch;
