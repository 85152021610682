import React, { useState, useEffect } from "react";
import { Box, Tab } from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { useLocation } from "react-router";
import { toast } from "react-toastify";

import AccountCircleTwoToneIcon from "@mui/icons-material/AccountCircleTwoTone";
import GamesIcon from "@mui/icons-material/Games";

import Profile from "./Profile";
import Games from "./Games";

const tabsOption = [
  {
    label: "Profile",
    icon: <AccountCircleTwoToneIcon sx={{ fontSize: "1.3rem" }} />,
  },
  {
    label: "Games",
    icon: <GamesIcon sx={{ fontSize: "1.3rem" }} />,
  },
];

function UserTabs() {
  const location = useLocation();
  const [activeTab, setActiveTab] = useState("1");
  const [userInfo, setUserInfo] = useState(null);
  const [viewStatus, setViewStatus] = useState("");

  useEffect(() => {
    const userInfoFromLocation = location?.state?.userInfo;
    const statusFromLocation = location?.state?.status;

    setViewStatus(statusFromLocation);

    if (userInfoFromLocation) {
      setUserInfo(userInfoFromLocation);
    }
  }, [location]);

  const handleTabChange = (event, newTab) => {
    setActiveTab(newTab);
  };

  return (
    <Box
      sx={{
        width: "90%",
        margin: "20px auto",
        backgroundColor: "white",
        padding: "20px",
        borderRadius: "10px",
      }}
    >
      <Box sx={{ width: "100%", typography: "body1" }}>
        <TabContext value={activeTab}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList
              onChange={handleTabChange}
              aria-label="user tabs"
              textColor="#800000"
              TabIndicatorProps={{
                style: { backgroundColor: "#800000" },
              }}
            >
              {tabsOption.map((tab, index) => (
                <Tab
                  key={index}
                  label={tab.label}
                  icon={tab.icon}
                  iconPosition="start"
                  value={(index + 1).toString()}
                  sx={{
                    color: activeTab === (index + 1).toString() ? "#800000" : "gray",
                  }}
                />
              ))}
            </TabList>
          </Box>
          <TabPanel value="1">
            <Profile userInfo={userInfo} />
          </TabPanel>
          <TabPanel value="2">
            <Games />
          </TabPanel>
        </TabContext>
      </Box>
    </Box>
  );
}

export default UserTabs;
