import { AxiosInstance } from "./AxiosInstance";

export const getAnalyticsData = async (startDate, endDate) => {
  let res;
  try {
    res = await AxiosInstance.get("/analytics/all-data", {
      params: {
        start_date: startDate,
        end_date: endDate,
      },
    });
    if (res.status === 401) {
      console.log("unauthorized");
    }
  } catch (err) {
    res = err.response;
  }

  return res ? res : "";
};
