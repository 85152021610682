import React from "react";
import { Navigate, Outlet } from "react-router-dom";

function ProtectedRoute(props) {
  // const token = auth.getToken();
  // const token = true;
  const token = localStorage.getItem("token");

  return token ? props.children : <Navigate to={"/login"} />;
  // return props.children;
}

export default ProtectedRoute;
