import React from "react";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { Avatar } from "@mui/material";

const ImageHandler = ({ profileImage, handleImageChange, status }) => {
  return (
    <div
      className="Avatar"
      style={{
        width: "100%",
        height: "18rem",
        justifyContent: "center",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <br />
      {profileImage ? (
        <Avatar
          src={profileImage}
          alt="Profile"
          className="profile-picture"
          style={{
            width: "225px", // Set the desired width
            height: "225px", // Set the desired height
            objectFit: "cover",
            borderRadius: "50%",
            margin: "30px auto 10px", // Center the Avatar horizontally
          }}
        />
      ) : (
        status !== "View" && (
          <>
            <Typography variant="caption" sx={{ textAlign: "center" }}>
              Upload/Update Your Profile Image
            </Typography>
            <br />
            <input
              accept="image/*"
              id="contained-button-file"
              type="file"
              style={{ display: "none" }}
              onChange={handleImageChange}
            />
            <label htmlFor="contained-button-file">
              <Button
                variant="contained"
                component="span"
                style={{
                  fontSize: "14px",
                  padding: "8px 12px",
                  borderRadius: "8px",
                  "&:hover": {
                    backgroundColor: "#eeeeee",
                  },
                  margin: "0 8px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Typography
                  variant="h2"
                  sx={{
                    fontFamily: "Outfit",
                    fontSize: "14px",
                    fontWeight: 400,
                    lineHeight: "24px",
                    letterSpacing: "0em",
                    textAlign: "center",
                    color: "white",
                  }}
                >
                  Choose Picture
                </Typography>
              </Button>
            </label>
          </>
        )
      )}
    </div>
  );
};

export default ImageHandler;
