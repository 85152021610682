import { IconBrandChrome, IconHelp } from '@tabler/icons';
import SupervisorAccountTwoToneIcon from '@mui/icons-material/SupervisorAccountTwoTone';
const icons = { IconBrandChrome, IconHelp };

const adminManagement = {
    id: 'admin-management',
    type: 'group',
    children: [
        {
            id: 'adminManagement',
            title: 'Admins Management',
            type: 'item',
            url: '/admin-management', // Adjust URL as needed
            icon: SupervisorAccountTwoToneIcon,
            breadcrumbs: false
        }
    ]
};

export default adminManagement;
