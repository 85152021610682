// assets
import { IconBrandChrome, IconHelp } from "@tabler/icons";
import LocationSearchingIcon from "@mui/icons-material/LocationSearching";
// constant
const icons = { IconBrandChrome, IconHelp };

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const feedback = {
  id: "sample-docs-roadmap-1",
  type: "group",
  children: [
    {
      id: "feedback",
      title: "Feedback Management",
      type: "item",
      url: "/feedback",
      icon: LocationSearchingIcon,
      breadcrumbs: false,
    },
  ],
};

export default feedback;
