import axios from "axios";

// Ensure that the baseURL is defined
if (!process.env.REACT_APP_API_URL) {
  throw new Error("REACT_APP_API_URL is not defined");
}

// Create an Axios instance with the base URL from environment variables
const AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

// Request interceptor to add the token to headers
AxiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers = {
        ...config.headers,
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      };
    }
    return config;
  },
  (error) => {
    // Handle request error
    console.error("Request interceptor error:", error);
    return Promise.reject(error);
  }
);

// Response interceptor to handle specific status codes
AxiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response) {
      // Handle unauthorized error
      if (error.response.status === 401) {
        localStorage.clear();
        window.location.href = "/";
      }
    } else {
      console.error("Response interceptor error:", error);
    }
    return Promise.reject(error);
  }
);

export { AxiosInstance };
