import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { getToken } from "../utils/getToken";
import auth from "../utils/auth";
function NonProtectedRoute(props) {
  // const token = auth.getToken();
  // const token = true;
  const token = localStorage.getItem("token");
  const newToken = localStorage.getItem("newToken");

  console.log(token);
  return !token ? props.children : <Navigate to={"/"} />;
  // return props.children;
}

export default NonProtectedRoute;
