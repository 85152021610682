export const statusOptions = [
    { value: 'today', label: 'Today' },
    { value: 'month', label: 'This Month' },
    { value: 'year', label: 'This Year' }
];

export const Registration = [
    { name: 'NewUser', color: '#A1D2FA' },
    { name: 'Registration', color: '#FF9922' }
];
export const Installation = [
    { name: 'Installs', color: '#00C853' },
    { name: 'UnInstalls', color: '#f54e4e' }
];
export const userGrowth = [
    // { name: 'User', color: '#409AE9' },
    { name: 'Registration', color: '#90CAF9' }
];

export const weeklyViewsData = [
    { date: '2023-05-01', views: 120, downloads: 30, appleUsers: 80, androidUsers: 40, activeAppleUsers: 60, activeAndroidUsers: 20 },
    { date: '2023-05-02', views: 150, downloads: 45, appleUsers: 90, androidUsers: 60, activeAppleUsers: 70, activeAndroidUsers: 30 },
    { date: '2023-05-03', views: 200, downloads: 60, appleUsers: 100, androidUsers: 100, activeAppleUsers: 80, activeAndroidUsers: 40 },
    { date: '2023-05-04', views: 250, downloads: 80, appleUsers: 120, androidUsers: 110, activeAppleUsers: 70, activeAndroidUsers: 30 },
    { date: '2023-05-05', views: 300, downloads: 100, appleUsers: 130, androidUsers: 120, activeAppleUsers: 90, activeAndroidUsers: 50 },
    { date: '2023-05-06', views: 350, downloads: 120, appleUsers: 135, androidUsers: 120, activeAppleUsers: 80, activeAndroidUsers: 30 },
    { date: '2023-05-07', views: 400, downloads: 140, appleUsers: 150, androidUsers: 140, activeAppleUsers: 70, activeAndroidUsers: 20 }

    // Add more weekly data points as needed
];

export const yearlyViewsData = [
    {
        date: '2020',
        views: 40000,
        downloads: 8000,
        appleUsers: 20000,
        androidUsers: 30000,
        activeAppleUsers: 15000,
        activeAndroidUsers: 5000
    },
    {
        date: '2021',
        views: 50000,
        downloads: 10000,
        appleUsers: 25000,
        androidUsers: 20000,
        activeAppleUsers: 20000,
        activeAndroidUsers: 8000
    },

    {
        date: '2022',
        views: 60000,
        downloads: 12000,
        appleUsers: 20000,
        androidUsers: 30000,
        activeAppleUsers: 15000,
        activeAndroidUsers: 5000
    },
    {
        date: '2023',
        views: 70000,
        downloads: 14000,
        appleUsers: 25000,
        androidUsers: 20000,
        activeAppleUsers: 20000,
        activeAndroidUsers: 8000
    }

    // Add more yearly data points as needed
];

export const monthlyViewsData = [
    { date: '2023-01', views: 3000, downloads: 700, appleUsers: 1500, androidUsers: 1500, activeAppleUsers: 1000, activeAndroidUsers: 500 },
    { date: '2023-02', views: 3500, downloads: 800, appleUsers: 1800, androidUsers: 1700, activeAppleUsers: 1200, activeAndroidUsers: 600 },
    { date: '2023-03', views: 4000, downloads: 900, appleUsers: 2000, androidUsers: 2000, activeAppleUsers: 1300, activeAndroidUsers: 700 },
    {
        date: '2023-04',
        views: 4500,
        downloads: 1000,
        appleUsers: 2200,
        androidUsers: 2300,
        activeAppleUsers: 1500,
        activeAndroidUsers: 800
    },
    {
        date: '2023-05',
        views: 5000,
        downloads: 1100,
        appleUsers: 2400,
        androidUsers: 2600,
        activeAppleUsers: 1700,
        activeAndroidUsers: 900
    },
    {
        date: '2023-06',
        views: 5500,
        downloads: 1200,
        appleUsers: 2700,
        androidUsers: 2800,
        activeAppleUsers: 1900,
        activeAndroidUsers: 1000
    },
    {
        date: '2023-07',
        views: 6000,
        downloads: 1300,
        appleUsers: 3000,
        androidUsers: 3000,
        activeAppleUsers: 2100,
        activeAndroidUsers: 1100
    },
    {
        date: '2023-08',
        views: 6500,
        downloads: 1400,
        appleUsers: 3200,
        androidUsers: 3300,
        activeAppleUsers: 2300,
        activeAndroidUsers: 1200
    },
    {
        date: '2023-09',
        views: 7000,
        downloads: 1500,
        appleUsers: 3500,
        androidUsers: 3500,
        activeAppleUsers: 2500,
        activeAndroidUsers: 1300
    },
    {
        date: '2023-10',
        views: 7500,
        downloads: 1600,
        appleUsers: 3800,
        androidUsers: 3700,
        activeAppleUsers: 2700,
        activeAndroidUsers: 1400
    },
    {
        date: '2023-11',
        views: 8000,
        downloads: 1700,
        appleUsers: 4000,
        androidUsers: 4000,
        activeAppleUsers: 2900,
        activeAndroidUsers: 1500
    },
    {
        date: '2023-12',
        views: 8500,
        downloads: 1800,
        appleUsers: 4300,
        androidUsers: 4200,
        activeAppleUsers: 3100,
        activeAndroidUsers: 1600
    }
];

// export const skillsData = [
//   {
//     category: "ARTS & ACTS",
//     count: 10,
//     NumberofGamesPlayed:12,
//     NumberofSessions:8,
//     AverageSessionLength:40,
//     NumberofFriends:10,
//     color: "primary",
//     subcategories: [
//       {
//         category: "Visual Arts",
//         count: 5,
//         NumberofGamesPlayed:12,
//         NumberofSessions:8,
//         AverageSessionLength:40,
//         NumberofFriends:10,
//         subcategories: [
//           {
//             category: "Traditional Drawing",
//             count: 3,
//             NumberofGamesPlayed:12,
//             NumberofSessions:8,
//             AverageSessionLength:40,
//             NumberofFriends:10,
//             subcategories: [
//               {
//                 category: "Pencil Sketching",
//                 count: 1,
//                 NumberofGamesPlayed:12,
//                 NumberofSessions:8,
//                 AverageSessionLength:40,
//                 NumberofFriends:10,
//               },
//               {
//                 category: "Charcoal Drawing",
//                 count: 1,
//                 NumberofGamesPlayed:12,
//                 NumberofSessions:8,
//                 AverageSessionLength:40,
//                 NumberofFriends:10,
//               },
//               {
//                 category: "Ink Drawing",
//                 count: 1,
//                 NumberofGamesPlayed:12,
//                 NumberofSessions:8,
//                 AverageSessionLength:40,
//                 NumberofFriends:10,
//               },
//               {
//                 category: "Figure Drawing",
//                 count: 1,
//                 NumberofGamesPlayed:12,
//                 NumberofSessions:8,
//                 AverageSessionLength:40,
//                 NumberofFriends:10,
//               },
//               {
//                 category: "Realism Drawing",
//                 count: 1,
//                 NumberofGamesPlayed:12,
//                 NumberofSessions:8,
//                 AverageSessionLength:40,
//                 NumberofFriends:10,
//               }
//             ]
//           },

//           {
//             category: "Watercolor Painting",
//             count: 3,
//             NumberofGamesPlayed:12,
//             NumberofSessions:8,
//             AverageSessionLength:40,
//             NumberofFriends:10,
//             subcategories: [
//               {
//                 category: "Subcategory 1.1.1.1",
//                 count: 1,
//               }
//             ]
//           },
//           {
//             category: "Sculpture",
//             count: 3,
//             NumberofGamesPlayed:12,
//             NumberofSessions:8,
//             AverageSessionLength:40,
//             NumberofFriends:10,
//             subcategories: [
//               {
//                 category: "Subcategory 1.1.1.1",
//                 count: 1,
//               }
//             ]
//           },
//           {
//             category: "Printmaking",
//             count: 3,
//             NumberofGamesPlayed:12,
//             NumberofSessions:8,
//             AverageSessionLength:40,
//             NumberofFriends:10,
//             subcategories: [
//               {
//                 category: "Subcategory 1.1.1.1",
//                 count: 1,
//               }
//             ]
//           },
//           {
//             category: "Mosaic Art",
//             count: 3,
//             NumberofGamesPlayed:12,
//             NumberofSessions:8,
//             AverageSessionLength:40,
//             NumberofFriends:10,
//             subcategories: [
//               {
//                 category: "Subcategory 1.1.1.1",
//                 count: 1,
//               }
//             ]
//           },
//           {
//             category: "Digital Art",
//             count: 3,
//             NumberofGamesPlayed:12,
//             NumberofSessions:8,
//             AverageSessionLength:40,
//             NumberofFriends:10,
//             subcategories: [
//               {
//                 category: "Subcategory 1.1.1.1",
//                 count: 1,
//               }
//             ]
//           }
//         ]
//       },
//       {
//         category: "Performing Arts",
//         count: 5,
//         NumberofGamesPlayed:12,
//         NumberofSessions:8,
//         AverageSessionLength:40,
//         NumberofFriends:10,
//         subcategories: [
//           {
//             category: "Subcategory 1.1.1",
//             count: 3,
//             NumberofGamesPlayed:12,
//             NumberofSessions:8,
//             AverageSessionLength:40,
//             NumberofFriends:10,
//             subcategories: [
//               {
//                 category: "Subcategory 1.1.1.1",
//                 count: 1,
//               }
//             ]
//           }
//         ]
//       },
//       {
//         category: "Photography",
//         count: 5,
//         NumberofGamesPlayed:12,
//         NumberofSessions:8,
//         AverageSessionLength:40,
//         NumberofFriends:10,
//         subcategories: [
//           {
//             category: "Subcategory 1.1.1",
//             count: 3,
//             NumberofGamesPlayed:12,
//             NumberofSessions:8,
//             AverageSessionLength:40,
//             NumberofFriends:10,
//             subcategories: [
//               {
//                 category: "Subcategory 1.1.1.1",
//                 count: 1,
//                 NumberofGamesPlayed:12,
//                 NumberofSessions:8,
//                 AverageSessionLength:40,
//                 NumberofFriends:10,
//               }
//             ]
//           }
//         ]
//       },
//       {
//         category: "Craftsmanship",
//         count: 5,
//         NumberofGamesPlayed:12,
//         NumberofSessions:8,
//         AverageSessionLength:40,
//         NumberofFriends:10,
//         subcategories: [
//           {
//             category: "Subcategory 1.1.1",
//             count: 3,
//             NumberofGamesPlayed:12,
//             NumberofSessions:8,
//             AverageSessionLength:40,
//             NumberofFriends:10,
//             subcategories: [
//               {
//                 category: "Subcategory 1.1.1.1",
//                 count: 1,
//               }
//             ]
//           }
//         ]
//       },
//       {
//         category: "Digital Design",
//         count: 5,
//         NumberofGamesPlayed:12,
//         NumberofSessions:8,
//         AverageSessionLength:40,
//         NumberofFriends:10,
//         subcategories: [
//           {
//             category: "Subcategory 1.1.1",
//             count: 3,
//             NumberofGamesPlayed:12,
//             NumberofSessions:8,
//             AverageSessionLength:40,
//             NumberofFriends:10,
//             subcategories: [
//               {
//                 category: "Subcategory 1.1.1.1",
//                 count: 1,
//               }
//             ]
//           }
//         ]
//       }
//     ]
//   },
//    {
//     category: "CULTURE & SOCIETY",
//     count: 10,
//     NumberofGamesPlayed:12,
//     NumberofSessions:8,
//     AverageSessionLength:40,
//     NumberofFriends:10,
//     color: "primary",
//     subcategories: [
//       {
//         category: "World History",
//         count: 5,
//         NumberofGamesPlayed:12,
//         NumberofSessions:8,
//         AverageSessionLength:40,
//         NumberofFriends:10,
//         subcategories: [
//           {
//             category: "Subcategory 1.1.1",
//             count: 3,
//             subcategories: [
//               {
//                 category: "Subcategory 1.1.1.1",
//                 count: 1,
//               }
//             ]
//           },
//           {
//             category: "Subcategory 1.1.1",
//             count: 3,
//             subcategories: [
//               {
//                 category: "Subcategory 1.1.1.1",
//                 count: 1,
//               }
//             ]
//           },
//           {
//             category: "Subcategory 1.1.1",
//             count: 3,
//             subcategories: [
//               {
//                 category: "Subcategory 1.1.1.1",
//                 count: 1,
//               }
//             ]
//           },
//           {
//             category: "Subcategory 1.1.1",
//             count: 3,
//             subcategories: [
//               {
//                 category: "Subcategory 1.1.1.1",
//                 count: 1,
//               }
//             ]
//           }
//         ]
//       },
//       {
//         category: "Languages & Linguistics",
//         count: 5,
//         NumberofGamesPlayed:12,
//         NumberofSessions:8,
//         AverageSessionLength:40,
//         NumberofFriends:10,
//         subcategories: [
//           {
//             category: "Subcategory 1.1.1",
//             count: 3,
//             subcategories: [
//               {
//                 category: "Subcategory 1.1.1.1",
//                 count: 1,
//               }
//             ]
//           },
//           {
//             category: "Subcategory 1.1.1",
//             count: 3,
//             subcategories: [
//               {
//                 category: "Subcategory 1.1.1.1",
//                 count: 1,
//               }
//             ]
//           },
//           {
//             category: "Subcategory 1.1.1",
//             count: 3,
//             subcategories: [
//               {
//                 category: "Subcategory 1.1.1.1",
//                 count: 1,
//               }
//             ]
//           },
//           {
//             category: "Subcategory 1.1.1",
//             count: 3,
//             subcategories: [
//               {
//                 category: "Subcategory 1.1.1.1",
//                 count: 1,
//               }
//             ]
//           }
//         ]
//       },
//       {
//         category: "Sociology",
//         count: 5,
//         NumberofGamesPlayed:12,
//         NumberofSessions:8,
//         AverageSessionLength:40,
//         NumberofFriends:10,
//         subcategories: [
//           {
//             category: "Subcategory 1.1.1",
//             count: 3,
//             subcategories: [
//               {
//                 category: "Subcategory 1.1.1.1",
//                 count: 1,
//               }
//             ]
//           },
//           {
//             category: "Subcategory 1.1.1",
//             count: 3,
//             subcategories: [
//               {
//                 category: "Subcategory 1.1.1.1",
//                 count: 1,
//               }
//             ]
//           },
//           {
//             category: "Subcategory 1.1.1",
//             count: 3,
//             subcategories: [
//               {
//                 category: "Subcategory 1.1.1.1",
//                 count: 1,
//               }
//             ]
//           },
//           {
//             category: "Subcategory 1.1.1",
//             count: 3,
//             subcategories: [
//               {
//                 category: "Subcategory 1.1.1.1",
//                 count: 1,
//               }
//             ]
//           }
//         ]
//       },
//       {
//         category: "Philosophy",
//         count: 5,
//         NumberofGamesPlayed:12,
//         NumberofSessions:8,
//         AverageSessionLength:40,
//         NumberofFriends:10,
//         subcategories: [
//           {
//             category: "Subcategory 1.1.1",
//             count: 3,
//             subcategories: [
//               {
//                 category: "Subcategory 1.1.1.1",
//                 count: 1,
//               }
//             ]
//           },
//           {
//             category: "Subcategory 1.1.1",
//             count: 3,
//             subcategories: [
//               {
//                 category: "Subcategory 1.1.1.1",
//                 count: 1,
//               }
//             ]
//           },
//           {
//             category: "Subcategory 1.1.1",
//             count: 3,
//             subcategories: [
//               {
//                 category: "Subcategory 1.1.1.1",
//                 count: 1,
//               }
//             ]
//           },
//           {
//             category: "Subcategory 1.1.1",
//             count: 3,
//             subcategories: [
//               {
//                 category: "Subcategory 1.1.1.1",
//                 count: 1,
//               }
//             ]
//           }
//         ]
//       },
//       {
//         category: "Ethics & Morality",

//         count: 5,
//         NumberofGamesPlayed:12,
//         NumberofSessions:8,
//         AverageSessionLength:40,
//         NumberofFriends:10,
//         subcategories: [
//           {
//             category: "Subcategory 1.1.1",
//             count: 3,
//             subcategories: [
//               {
//                 category: "Subcategory 1.1.1.1",
//                 count: 1,
//               }
//             ]
//           },
//           {
//             category: "Subcategory 1.1.1",
//             count: 3,
//             subcategories: [
//               {
//                 category: "Subcategory 1.1.1.1",
//                 count: 1,
//               }
//             ]
//           },
//           {
//             category: "Subcategory 1.1.1",
//             count: 3,
//             subcategories: [
//               {
//                 category: "Subcategory 1.1.1.1",
//                 count: 1,
//               }
//             ]
//           },
//           {
//             category: "Subcategory 1.1.1",
//             count: 3,
//             subcategories: [
//               {
//                 category: "Subcategory 1.1.1.1",
//                 count: 1,
//               }
//             ]
//           }
//         ]
//       },
//       {
//         category: "Geography & Travel",
//         count: 5,
//         NumberofGamesPlayed:12,
//         NumberofSessions:8,
//         AverageSessionLength:40,
//         NumberofFriends:10,
//         subcategories: [
//           {
//             category: "Subcategory 1.1.1",
//             count: 3,
//             subcategories: [
//               {
//                 category: "Subcategory 1.1.1.1",
//                 count: 1,
//               }
//             ]
//           },
//           {
//             category: "Subcategory 1.1.1",
//             count: 3,
//             subcategories: [
//               {
//                 category: "Subcategory 1.1.1.1",
//                 count: 1,
//               }
//             ]
//           },
//           {
//             category: "Subcategory 1.1.1",
//             count: 3,
//             subcategories: [
//               {
//                 category: "Subcategory 1.1.1.1",
//                 count: 1,
//               }
//             ]
//           },
//           {
//             category: "Subcategory 1.1.1",
//             count: 3,
//             subcategories: [
//               {
//                 category: "Subcategory 1.1.1.1",
//                 count: 1,
//               }
//             ]
//           }
//         ]
//       }
//     ]
//   },
//   {
//     category: "MEDIA & MUSIC",
//     count: 10,
//     NumberofGamesPlayed:12,
//     NumberofSessions:8,
//     AverageSessionLength:40,
//     NumberofFriends:10,
//     color: "primary",
//     subcategories: [
//       {
//         category: "Digital Media Production",
//         count: 5,
//         NumberofGamesPlayed:12,
//         NumberofSessions:8,
//         AverageSessionLength:40,
//         NumberofFriends:10,
//         subcategories: [
//           {
//             category: "Subcategory 1.1.1",
//             count: 3,
//             subcategories: [
//               {
//                 category: "Subcategory 1.1.1.1",
//                 count: 1,
//               }
//             ]
//           },
//           {
//             category: "Subcategory 1.1.1",
//             count: 3,
//             subcategories: [
//               {
//                 category: "Subcategory 1.1.1.1",
//                 count: 1,
//               }
//             ]
//           },
//           {
//             category: "Subcategory 1.1.1",
//             count: 3,
//             subcategories: [
//               {
//                 category: "Subcategory 1.1.1.1",
//                 count: 1,
//               }
//             ]
//           },
//           {
//             category: "Subcategory 1.1.1",
//             count: 3,
//             subcategories: [
//               {
//                 category: "Subcategory 1.1.1.1",
//                 count: 1,
//               }
//             ]
//           }
//         ]
//       },
//       {
//         category: "Journalism",
//         count: 5,
//         NumberofGamesPlayed:12,
//         NumberofSessions:8,
//         AverageSessionLength:40,
//         NumberofFriends:10,
//         subcategories: [
//           {
//             category: "Subcategory 1.1.1",
//             count: 3,
//             NumberofGamesPlayed:12,
//             NumberofSessions:8,
//             AverageSessionLength:40,
//             NumberofFriends:10,
//             subcategories: [
//               {
//                 category: "Subcategory 1.1.1.1",
//                 count: 1,
//               }
//             ]
//           },
//           {
//             category: "Subcategory 1.1.1",
//             count: 3,
//             subcategories: [
//               {
//                 category: "Subcategory 1.1.1.1",
//                 count: 1,
//               }
//             ]
//           },
//           {
//             category: "Subcategory 1.1.1",
//             count: 3,
//             subcategories: [
//               {
//                 category: "Subcategory 1.1.1.1",
//                 count: 1,
//               }
//             ]
//           },
//           {
//             category: "Subcategory 1.1.1",
//             count: 3,
//             subcategories: [
//               {
//                 category: "Subcategory 1.1.1.1",
//                 count: 1,
//               }
//             ]
//           }
//         ]
//       },
//       {
//         category: "Broadcasting",
//         count: 5,
//         NumberofGamesPlayed:12,
//         NumberofSessions:8,
//         AverageSessionLength:40,
//         NumberofFriends:10,
//         subcategories: [
//           {
//             category: "Subcategory 1.1.1",
//             count: 3,
//             NumberofGamesPlayed:12,
//             NumberofSessions:8,
//             AverageSessionLength:40,
//             NumberofFriends:10,
//             subcategories: [
//               {
//                 category: "Subcategory 1.1.1.1",
//                 count: 1,
//               }
//             ]
//           },
//           {
//             category: "Subcategory 1.1.1",
//             count: 3,
//             subcategories: [
//               {
//                 category: "Subcategory 1.1.1.1",
//                 count: 1,
//               }
//             ]
//           },
//           {
//             category: "Subcategory 1.1.1",
//             count: 3,
//             subcategories: [
//               {
//                 category: "Subcategory 1.1.1.1",
//                 count: 1,
//               }
//             ]
//           },
//           {
//             category: "Subcategory 1.1.1",
//             count: 3,
//             subcategories: [
//               {
//                 category: "Subcategory 1.1.1.1",
//                 count: 1,
//               }
//             ]
//           }
//         ]
//       },
//       {
//         category: "Animation",
//         count: 5,
//         NumberofGamesPlayed:12,
//         NumberofSessions:8,
//         AverageSessionLength:40,
//         NumberofFriends:10,
//         subcategories: [
//           {
//             category: "Subcategory 1.1.1",
//             count: 3,
//             subcategories: [
//               {
//                 category: "Subcategory 1.1.1.1",
//                 count: 1,
//               }
//             ]
//           },
//           {
//             category: "Subcategory 1.1.1",
//             count: 3,
//             subcategories: [
//               {
//                 category: "Subcategory 1.1.1.1",
//                 count: 1,
//               }
//             ]
//           },
//           {
//             category: "Subcategory 1.1.1",
//             count: 3,
//             subcategories: [
//               {
//                 category: "Subcategory 1.1.1.1",
//                 count: 1,
//               }
//             ]
//           },
//           {
//             category: "Subcategory 1.1.1",
//             count: 3,
//             subcategories: [
//               {
//                 category: "Subcategory 1.1.1.1",
//                 count: 1,
//               }
//             ]
//           }
//         ]
//       },
//       {
//         category: "Film Scoring",
//         count: 5,
//         NumberofGamesPlayed:12,
//         NumberofSessions:8,
//         AverageSessionLength:40,
//         NumberofFriends:10,
//         subcategories: [
//           {
//             category: "Subcategory 1.1.1",
//             count: 3,
//             subcategories: [
//               {
//                 category: "Subcategory 1.1.1.1",
//                 count: 1,
//               }
//             ]
//           },
//           {
//             category: "Subcategory 1.1.1",
//             count: 3,
//             subcategories: [
//               {
//                 category: "Subcategory 1.1.1.1",
//                 count: 1,
//               }
//             ]
//           },
//           {
//             category: "Subcategory 1.1.1",
//             count: 3,
//             subcategories: [
//               {
//                 category: "Subcategory 1.1.1.1",
//                 count: 1,
//               }
//             ]
//           },
//           {
//             category: "Subcategory 1.1.1",
//             count: 3,
//             subcategories: [
//               {
//                 category: "Subcategory 1.1.1.1",
//                 count: 1,
//               }
//             ]
//           }
//         ]
//       },
//       {
//         category: "Digital Media Production",
//         count: 5,
//         NumberofGamesPlayed:12,
//         NumberofSessions:8,
//         AverageSessionLength:40,
//         NumberofFriends:10,
//         subcategories: [
//           {
//             category: "Subcategory 1.1.1",
//             count: 3,
//             subcategories: [
//               {
//                 category: "Subcategory 1.1.1.1",
//                 count: 1,
//               }
//             ]
//           },
//           {
//             category: "Subcategory 1.1.1",
//             count: 3,
//             subcategories: [
//               {
//                 category: "Subcategory 1.1.1.1",
//                 count: 1,
//               }
//             ]
//           },
//           {
//             category: "Subcategory 1.1.1",
//             count: 3,
//             subcategories: [
//               {
//                 category: "Subcategory 1.1.1.1",
//                 count: 1,
//               }
//             ]
//           },
//           {
//             category: "Subcategory 1.1.1",
//             count: 3,
//             subcategories: [
//               {
//                 category: "Subcategory 1.1.1.1",
//                 count: 1,
//               }
//             ]
//           }
//         ]
//       }
//     ]
//   },
//   {
//     category: "SCIENCE & TECHNOLOGY",
//     count: 10,
//     NumberofGamesPlayed:12,
//     NumberofSessions:8,
//     AverageSessionLength:40,
//     NumberofFriends:10,
//     color: "primary",
//     subcategories: [
//       {
//         category: "Subcategory 1.1",
//         count: 5,
//         subcategories: [
//           {
//             category: "Subcategory 1.1.1",
//             count: 3,
//             subcategories: [
//               {
//                 category: "Subcategory 1.1.1.1",
//                 count: 1,
//               }
//             ]
//           },
//           {
//             category: "Subcategory 1.1.1",
//             count: 3,
//             subcategories: [
//               {
//                 category: "Subcategory 1.1.1.1",
//                 count: 1,
//               }
//             ]
//           },
//           {
//             category: "Subcategory 1.1.1",
//             count: 3,
//             subcategories: [
//               {
//                 category: "Subcategory 1.1.1.1",
//                 count: 1,
//               }
//             ]
//           },
//           {
//             category: "Subcategory 1.1.1",
//             count: 3,
//             subcategories: [
//               {
//                 category: "Subcategory 1.1.1.1",
//                 count: 1,
//               }
//             ]
//           }
//         ]
//       }
//     ]
//   },
//   {
//     category: "SPORTS & RECREATION",
//     count: 10,
//     NumberofGamesPlayed:12,
//     NumberofSessions:8,
//     AverageSessionLength:40,
//     NumberofFriends:10,
//     color: "primary",
//     subcategories: [
//       {
//         category: "Subcategory 1.1",
//         count: 5,
//         subcategories: [
//           {
//             category: "Subcategory 1.1.1",
//             count: 3,
//             subcategories: [
//               {
//                 category: "Subcategory 1.1.1.1",
//                 count: 1,
//               }
//             ]
//           },
//           {
//             category: "Subcategory 1.1.1",
//             count: 3,
//             subcategories: [
//               {
//                 category: "Subcategory 1.1.1.1",
//                 count: 1,
//               }
//             ]
//           },
//           {
//             category: "Subcategory 1.1.1",
//             count: 3,
//             subcategories: [
//               {
//                 category: "Subcategory 1.1.1.1",
//                 count: 1,
//               }
//             ]
//           },
//           {
//             category: "Subcategory 1.1.1",
//             count: 3,
//             subcategories: [
//               {
//                 category: "Subcategory 1.1.1.1",
//                 count: 1,
//               }
//             ]
//           }
//         ]
//       }
//     ]
//   },
//   {
//     category: "THIS & THAT",
//     count: 10,
//     NumberofGamesPlayed:12,
//     NumberofSessions:8,
//     AverageSessionLength:40,
//     NumberofFriends:10,
//     color: "primary",
//     subcategories: [
//       {
//         category: "Subcategory 1.1",
//         count: 5,
//         subcategories: [
//           {
//             category: "Subcategory 1.1.1",
//             count: 3,
//             subcategories: [
//               {
//                 category: "Subcategory 1.1.1.1",
//                 count: 1,
//               }
//             ]
//           },
//           {
//             category: "Subcategory 1.1.1",
//             count: 3,
//             subcategories: [
//               {
//                 category: "Subcategory 1.1.1.1",
//                 count: 1,
//               }
//             ]
//           },
//           {
//             category: "Subcategory 1.1.1",
//             count: 3,
//             subcategories: [
//               {
//                 category: "Subcategory 1.1.1.1",
//                 count: 1,
//               }
//             ]
//           },
//           {
//             category: "Subcategory 1.1.1",
//             count: 3,
//             subcategories: [
//               {
//                 category: "Subcategory 1.1.1.1",
//                 count: 1,
//               }
//             ]
//           }
//         ]
//       }
//     ]
//   },

//   // other categories...
// ];
export const skillsData = [
    {
        category: 'ARTS & ACTS',
        count: 180,
        NumberofGamesPlayed: 0,
        NumberofSessions: 0,
        AverageSessionLength: 0,
        NumberofFriends: 0,
        color: 'primary',
        subcategories: [
            {
                category: 'Visual Arts',
                count: 20,
                NumberofGamesPlayed: 0,
                NumberofSessions: 0,
                AverageSessionLength: 0,
                NumberofFriends: 0,
                subcategories: [
                    {
                        category: 'Traditional Drawing',
                        count: 5,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Pencil Sketching',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Charcoal Drawing',
                                count: 3,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Ink Drawing',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Figure Drawing',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Realism Drawing',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Watercolor Painting',
                        count: 5,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Landscape Painting',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Still Life Painting',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Abstract Painting',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Portrait Painting',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Impressionism',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Sculpture',
                        count: 4,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Clay Modeling',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Stone Carving',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Wood Carving',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Metal Sculpting',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Found Object Sculpture',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Calligraphy',
                        count: 6,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Modern Calligraphy',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Brush Calligraphy',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Copperplate Calligraphy',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Italic Calligraphy',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Arabic Calligraphy',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Printmaking',
                        count: 10,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Woodcut Printing',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Etching',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Linocut Printing',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Screen Printing',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Mosaic Art',
                        count: 19,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Ceramic Mosaics',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Glass Mosaics',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Stone Mosaics',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Mixed Media Mosaics',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Contemporary Mosaics',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Digital Art',
                        count: 16,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Digital Painting',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: '3D Modeling',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Animation',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Graphic Design',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Digital Illustration',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Monotype Printing',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Mixed Media Art',
                        count: 18,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Collage',
                                count: 9,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Assemblage',
                                count: 10,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Mixed Media Painting',
                                count: 7,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Found Object Art',
                                count: 4,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Digital Mixed Media',
                                count: 12,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    }
                ]
            },
            {
                category: 'Performing Arts',
                count: 22,
                NumberofGamesPlayed: 0,
                NumberofSessions: 0,
                AverageSessionLength: 0,
                NumberofFriends: 0,
                subcategories: [
                    {
                        category: 'Acting',
                        count: 5,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Improvisation',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Scene Study',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Character Development',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Script Analysis',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Method Acting',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Dance',
                        count: 5,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Ballet',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Contemporary Dance',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Hip Hop Dance',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Ballroom Dance',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Jazz Dance',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Stage Performance',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Theater Acting',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Physical Theater',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Comedy Acting',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Classical Theater',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Musical Theater',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Circus Arts',
                        count: 6,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Juggling',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Acrobatics',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Tightrope Walking',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Clowning',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Fire Dancing',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Magic',
                        count: 6,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Mentalism',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Close-up Magic',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Stage Magic',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Illusionism',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Escapology',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Film and Television',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Directing',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Screenwriting',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Cinematography',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Film Editing',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Acting for Film and Television',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Circus Performance',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Aerial Arts',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Acrobatics',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Clowning',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Juggling and Object Manipulation',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Circus Performance Skill',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Voice Acting',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Character Voice Acting',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Commercial Voice Acting',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Audiobook Narration',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Voice Acting Techniques',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Voiceover Performance',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    }
                ]
            },
            {
                category: 'Photography',
                count: 19,
                NumberofGamesPlayed: 0,
                NumberofSessions: 0,
                AverageSessionLength: 0,
                NumberofFriends: 0,
                subcategories: [
                    {
                        category: 'Portrait Photography',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Headshot Photography',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Fine Art Portraits',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Environmental Portraits',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Candid Portraits',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Family Portraits',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Landscape Photography',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Seascape Photography',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Urban Landscape Photography',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Nature Photography',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Travel Photography',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Long Exposure Photography',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Street Photography',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Urban Photography',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Documentary Street Photography',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Candid Street Photography',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Fine Art Street Photography',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Night Street Photography',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Product Photography',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Food Photography',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Commercial Product Photography',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Still Life Photography',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Product Styling',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'E-commerce Product Photography',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Event Photography',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Wedding Photography',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Event Coverage Photography',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Concert Photography',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Sports Event Photography',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Corporate Event Photography',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Fashion Photography\n',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Editorial Fashion Photography',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Runway Fashion Photography',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Lookbook Photography',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Catalog Photography',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Commercial Fashion Photography',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Wildlife Photography',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Bird Photography',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Mammal Photography',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Reptile and Amphibian Photography',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Insect and Macro Photography',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Underwater Wildlife Photography',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Architectural Photography',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Exterior Architecture Photography',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Interior Architecture Photography',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Urban Exploration Photography',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Architectural Detail Photography',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Aerial Photography',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    }
                ]
            },
            {
                category: 'Craftsmanship',
                count: 24,
                NumberofGamesPlayed: 0,
                NumberofSessions: 0,
                AverageSessionLength: 0,
                NumberofFriends: 0,
                subcategories: [
                    {
                        category: 'Woodworking',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Furniture Making',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Cabinet Making',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Wood Carving',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Turning',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Joinery',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Leathercraft',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Handbag Making',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Shoe Making',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Tooling',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Saddle Making',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Leather Carving',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Textile Crafts',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Embroidery',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Quilting',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Weaving',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Knitting',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Dyeing',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Paper Crafts',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Origami',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Bookbinding',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Card Making',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Scrapbooking',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Papercutting',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Jewelry Making',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Beading',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Metalworking',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Wire Wrapping',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Lapidary',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Precious Metal Clay',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Metalworking',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Blacksmithing',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Welding',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Jewelry Metalworking',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Metal Sculpture',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Metal Engraving',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Ceramics\n',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Pottery',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Ceramic Sculpture',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Wheel Throwing',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Hand-building Techniques',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Glazing and Firing',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Glass Crafts',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Stained Glass',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Glassblowing',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Fused Glass',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Glass Etching',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Lampworking',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    }
                ]
            },
            {
                category: 'Digital Design',
                count: 21,
                NumberofGamesPlayed: 0,
                NumberofSessions: 0,
                AverageSessionLength: 0,
                NumberofFriends: 0,
                subcategories: [
                    {
                        category: 'Graphic Design',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Logo Design',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Poster Design',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Branding Design',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Publication Design',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Package Design',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'UI/UX Design',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Web Design',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Mobile App Design',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'User Research',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Wireframing',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Prototyping',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Digital Illustration',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Character Design',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Concept Art',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Storyboarding',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Vector Illustration',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Comic Illustration',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Animation',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: '2D Animation',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: '3D Animation',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Stop Motion Animation',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Character Animation',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Special Effects Animation',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Game Design',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Level Design',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Game Mechanics',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Game Art',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Game Development',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'User Interface Design',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Web Design',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Responsive Design',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'HTML/CSS',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'User Interface Design',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Wireframing',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Prototyping',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Motion Graphics',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Video Editing',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Visual Effects (VFX)',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Motion Design Principles',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Kinetic Typography',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Animated Infographics',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: '3D Modeling',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Character Modeling',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Environmental Modeling',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Product Design',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Architectural Visualization',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Organic Modeling',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    }
                ]
            },
            {
                category: 'Culinary Arts',
                count: 21,
                NumberofGamesPlayed: 0,
                NumberofSessions: 0,
                AverageSessionLength: 0,
                NumberofFriends: 0,
                subcategories: [
                    {
                        category: 'Cooking',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'International Cuisine',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Baking',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Vegetarian & Vegan Cuisine',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Healthy Cooking',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Home Cooking Techniques',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Baking',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Bread Making',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Pastry Making',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Cake Decorating',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Dessert Making',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Chocolate Making',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Pastry',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Artisan Bread Making',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'French Pastries',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Italian Pastries',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Pie Making',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Cake Design',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Beverage',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Coffee Brewing',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Tea Culture',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Cocktail Mixology',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Wine Appreciation',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Beer Brewing',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Fine Dining',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Gastronomy',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Plating Techniques',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: "Chef's Table Experience",
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Food Pairing',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Fusion Cuisine',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Food Entrepreneurship',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Food Business Startups',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Marketing Strategies',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Branding',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Sales and Distribution',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Food Safety Regulations',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Hospitality Management',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Hotel Operations',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Restaurant Management',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Event Planning',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Customer Service',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Revenue Management',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Nutrition and Wellness',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Dietary Guidelines',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Meal Planning',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Nutrient Analysis',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Dietary Restrictions',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Nutrition Counseling',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    }
                ]
            },
            {
                category: 'Literary Arts',
                count: 21,
                NumberofGamesPlayed: 0,
                NumberofSessions: 0,
                AverageSessionLength: 0,
                NumberofFriends: 0,
                subcategories: [
                    {
                        category: 'Writing',
                        count: 10,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Creative Writing',
                                count: 12,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Journaling',
                                count: 11,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Screenwriting',
                                count: 8,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Poetry Writing',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Fiction Writing',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Poetry',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Spoken Word Poetry',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Haiku',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Sonnet',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Lyric Poetry',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Narrative Poetry',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Storytelling',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Interactive Storytelling',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Story Structure',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Character Development',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Plot Development',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Point of View',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Literature Studies',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Literary Analysis',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Literary Criticism',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Genre Studies',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Author Studies',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Comparative Literature',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Creative Writing Workshops',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Fiction Writing Workshops',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Poetry Writing Workshops',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Screenwriting Workshops',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Short Story Workshops',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Non-fiction Writing Workshops',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Literary Criticism',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Literary Theory',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Critical Approaches',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Textual Analysis',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Comparative Literature',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Cultural Studies',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Creative Nonfiction',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Personal Essay',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Memoir Writing',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Travel Writing',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Literary Journalism',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Narrative Medicine',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Journalism',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Investigative Reporting',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Feature Writing',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'News Writing',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Interview Techniques',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    }
                ]
            },
            {
                category: 'Fashion Design',
                count: 0,
                NumberofGamesPlayed: 0,
                NumberofSessions: 0,
                AverageSessionLength: 0,
                NumberofFriends: 0,
                subcategories: [
                    {
                        category: 'Fashion Illustration',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Press Ethics',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Flat Sketching',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Technical Drawing',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Model Illustration',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Portfolio Development',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Pattern Making',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Pattern Drafting',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Grading',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Draping',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Marker Making',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Pattern Manipulation',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Apparel Construction',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Sewing Techniques',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Pattern Sewing',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Couture Sewing',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Machine Sewing',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Finishing Techniques',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Textile Design',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Print Design',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Weaving',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Textile Printing',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Fabric Manipulation',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Surface Design',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Accessory Design',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Shoe Design',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Bag Design',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Jewelry Design',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Millinery',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Accessory Prototyping',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Sustainable Fashion',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Eco-Friendly Materials',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Ethical Manufacturing',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Zero-Waste Design',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Upcycling and Recycling',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Fair Trade Practices',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Costume Design',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Film and Television Costumes',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Theatre Costumes',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Historical Costumes',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Fantasy and Sci-Fi Costumes',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Character Costume Design',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Fashion Photography',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Editorial Fashion Photography',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Lookbook Photography',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Campaign Photography',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Fashion Portraits',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Product Photography',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    }
                ]
            },
            {
                category: 'Film Production',
                count: 0,
                NumberofGamesPlayed: 0,
                NumberofSessions: 0,
                AverageSessionLength: 0,
                NumberofFriends: 0,
                subcategories: [
                    {
                        category: 'Filmmaking',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Short Film Production',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Feature Film Production',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Documentary Filmmaking',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Experimental Filmmaking',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Indie Filmmaking',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Documentary',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Non-fiction Filmmaking',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Documentary Editing',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Interview Techniques',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Archival Research',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Location Scouting',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Screenwriting',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Script Analysis',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Dialogue Writing',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Plot Development',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Character Arcs',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Screenplay Formatting',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Directing',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Scene Blocking',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Storyboarding',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Casting',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Location Management',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Working with Actors',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Editing',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Color Grading',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Sound Editing',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Visual Effects',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Post-production Workflow',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Editing Software',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Sound Design',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Sound Recording',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Foley Artistry',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Dialogue Editing',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Sound Mixing',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Sound Effects Creation',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Production Design',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Set Design',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Art Direction',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Prop Mastering',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Costume Design',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Location Scouting',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Visual Effects (VFX)',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'CGI Animation',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Motion Graphics',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Matte Painting',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Green Screen Compositing',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: '3D Modeling',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    }
                ]
            },
            {
                category: 'Graphic Novels & Comics',
                count: 0,
                NumberofGamesPlayed: 0,
                NumberofSessions: 0,
                AverageSessionLength: 0,
                NumberofFriends: 0,
                subcategories: [
                    {
                        category: 'Comic Art',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Storyboarding',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Inking',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Coloring',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Lettering',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Panel Layout',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Graphic Novels',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Webcomics',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Manga',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Superhero Comics',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Indie Comics',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Graphic Memoirs',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Webcomics',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Long-form Webcomics',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Short-form Webcomics',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Serial Webcomics',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Interactive Webcomics',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Anthology Webcomics',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Manga',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Shonen Manga',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Shojo Manga',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Seinen Manga',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Josei Manga',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Kodomo Manga',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Character Design',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Concept Art',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Costume Design',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Character Anatomy',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Expression Design',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Model Sheets',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Storyboarding',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Visual Storytelling',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Panel Layout',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Narrative Flow',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Thumbnails',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Story Beats',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Inking',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Line Weight Variation',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Cross-Hatching',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Ink Wash',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Brush Techniques',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Digital Inking',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Coloring',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Flatting',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Rendering',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Shading',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Color Theory',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Digital Coloring',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    }
                ]
            }
        ]
    },
    {
        category: 'CULTURE & SOCIETY',
        count: 120,
        NumberofGamesPlayed: 0,
        NumberofSessions: 0,
        AverageSessionLength: 0,
        NumberofFriends: 0,
        color: 'primary',
        subcategories: [
            {
                category: 'World History',
                count: 12,
                NumberofGamesPlayed: 0,
                NumberofSessions: 0,
                AverageSessionLength: 0,
                NumberofFriends: 0,
                subcategories: [
                    {
                        category: 'Historical Events',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'War and Conflict',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Revolutions',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Industrialization',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Exploration and Discovery',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Cultural Exchange',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Historical Figures',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Political Leaders',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Intellectuals',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Artists and Writers',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Scientists and Inventors',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Religious Figures',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Civilizations',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Mesopotamian Civilization',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Indus Valley Civilization',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Mesoamerican Civilization',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Inca Civilization',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Aztec Civilization',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Cultural Movements',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Romanticism',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Postmodernism',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Environmentalism',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Civil Rights Movement',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Decolonization',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Wars and Conflicts',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'World War I',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'World War II',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Cold War',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Vietnam War',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Civil Wars',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Ancient History',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Mesopotamia',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Ancient Egypt',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Ancient Greece',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Ancient Rome',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Pre-Columbian Civilizations',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Renaissance and Reformation',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Italian Renaissance',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Protestant Reformation',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Age of Discovery',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Scientific Revolution',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Counter-Reformation',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Modern History',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Industrial Revolution',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'World Wars',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Cold War',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Decolonization',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Globalization',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    }
                ]
            },
            {
                category: 'Languages & Linguistics',
                count: 12,
                NumberofGamesPlayed: 0,
                NumberofSessions: 0,
                AverageSessionLength: 0,
                NumberofFriends: 0,
                subcategories: [
                    {
                        category: 'Languages',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Romance Languages',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Afro-Asiatic Languages',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Dravidian Languages',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Sino-Tibetan Languages',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Austronesian Languages',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Language Acquisition',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Language Assessment',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Language Policy',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Bilingual Education',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Language Developmental Milestones',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Language Acquisition Theories',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Linguistic Typology',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Morphological Typology',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Syntactic Typology',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Phonological Typology',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Semantic Typology',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Pragmatic Typology',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Sociolinguistics',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Language Variation',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Language Contact',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Language Policy',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Dialectology',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Language and Gender',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Historical Linguistics',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Language Change',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Comparative Linguistics',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Etymology',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Paleolinguistics',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Diachronic Analysis',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Psycholinguistics',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Language Processing',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Language Acquisition',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Language Disorders',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Bilingualism and Multilingualism',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Neurolinguistics',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Language Contact and Change',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Borrowing and Loanwords',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Language Contact Situations',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Language Shift and Endangerment',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Historical Linguistics',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Language Revitalization',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Language Structure',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Phonetics and Phonology',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Morphology',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Syntax',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Semantics',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Pragmatics',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    }
                ]
            },
            {
                category: 'Sociology',
                count: 12,
                NumberofGamesPlayed: 0,
                NumberofSessions: 0,
                AverageSessionLength: 0,
                NumberofFriends: 0,
                subcategories: [
                    {
                        category: 'Social Structures',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Family',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Education',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Religion',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Government',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Economy',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Social Change and Movements',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Civil Rights Movements',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Environmental Movements',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Labor Movements',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Feminist Movements',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'LGBTQ+ Rights Movements',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Research Methods',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Surveys',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Interviews',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Observational Studies',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Case Studies',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Content Analysis',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Sociological Theory',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Structural Functionalism',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Conflict Theory',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Symbolic Interactionism',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Feminist Theory',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Postmodernism',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Social Stratification',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Class Stratification',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Race and Ethnic Stratification',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Gender Stratification',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Caste Systems',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Global Inequality',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Social Institutions',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Family',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Education',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Religion',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Economy',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Politics',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Community and Urban Sociology',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Urbanization',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Community Development',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Urban Planning',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Gentrification',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Suburbanization',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Health Sociology',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Health Disparities',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Medical Sociology',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Public Health',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Healthcare Systems',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Health Behavior',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    }
                ]
            },
            {
                category: 'Cultural Anthropology',
                count: 12,
                NumberofGamesPlayed: 0,
                NumberofSessions: 0,
                AverageSessionLength: 0,
                NumberofFriends: 0,
                subcategories: [
                    {
                        category: 'Cultural Diversity',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Ethnicity',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Multiculturalism',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Cultural Identity',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Intercultural Communication',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Cultural Assimilation',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Social Organization',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Community',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Tribe',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Clan',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Chiefdom',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'State',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Cultural Practices',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Festivals and Celebrations',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Artifacts and Material Culture',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Customs and Traditions',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Rituals and Ceremonies',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Language and Communication',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Cultural Identity',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Ethnic Identity',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'National Identity',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Religious Identity',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Gender Identity',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Indigenous Identity',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Rituals and Ceremonies',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Rites of Passage',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Religious Rituals',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Festivals and Celebrations',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Healing Rituals',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Commemorative Ceremonies',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Language and Communication',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Linguistic Anthropology',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Verbal Communication',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Nonverbal Communication',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Language Evolution',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Discourse Analysis',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Material Culture',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Technology and Material Culture',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Artifacts and Tools',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Architecture',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Clothing and Adornments',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Food and Cuisine',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Religion and Belief Systems',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Religious Rituals',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Mythology and Folklore',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Sacred Spaces',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Religious Symbols',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Shamanism and Spirituality',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    }
                ]
            },
            {
                category: 'Philosophy',
                count: 12,
                NumberofGamesPlayed: 0,
                NumberofSessions: 0,
                AverageSessionLength: 0,
                NumberofFriends: 0,
                subcategories: [
                    {
                        category: 'Existentialism',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Absurdism',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Phenomenology',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Nihilism',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Existential Therapy',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Existential Literature',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Epistemology',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Empiricism',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Rationalism',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Skepticism',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Constructivism',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Pragmatism',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Logic',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Deductive Reasoning',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Inductive Reasoning',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Symbolic Logic',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Modal Logic',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Non-Classical Logic',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Philosophical Traditions',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Western Philosophy',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Eastern Philosophy',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'African Philosophy',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Islamic Philosophy',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Indigenous Philosophy',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Philosophy of Mind',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Consciousness',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Mind-Body Problem',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Artificial Intelligence',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Qualia',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Dualism',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Political Philosophy',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Social Contract Theory',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Justice and Rights',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Democracy and Governance',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Political Ideologies',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Power and Authority',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Aesthetics',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'The Nature of Beauty',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Art Criticism',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Philosophy of Art',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Aesthetic Experience',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Artistic Expression',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Philosophy of Religion',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Existence of God',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Problem of Evil',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Religious Experience',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Faith and Reason',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Religion and Science',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    }
                ]
            },
            {
                category: 'Psychology',
                count: 12,
                NumberofGamesPlayed: 0,
                NumberofSessions: 0,
                AverageSessionLength: 0,
                NumberofFriends: 0,
                subcategories: [
                    {
                        category: 'Cognitive Psychology',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Memory',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Perception',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Attention',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Problem Solving',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Language',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Social Psychology',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Social Cognition',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Attitudes',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Interpersonal Relationships',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Group Dynamics',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Social Influence',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Developmental Psychology',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Child Development',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Adolescence',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Adult Development',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Aging',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Lifespan Development',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Abnormal Psychology',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Mood Disorders',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Anxiety Disorders',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Personality Disorders',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Psychotic Disorders',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Trauma and Stressor-Related Disorders',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Positive Psychology',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Happiness',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Well-Being',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Resilience',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Gratitude',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Mindfulness',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Industrial-Organizational Psychology',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Work Motivation',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Organizational Behavior',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Leadership Development',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Employee Selection',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Performance Appraisal',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Educational Psychology',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Learning Theories',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Educational Assessment',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Classroom Management',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Educational Interventions',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Instructional Design',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Clinical Psychology',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Psychotherapy Approaches',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Psychopathology',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Diagnostic Methods',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Therapeutic Techniques',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Mental Health Assessment',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    }
                ]
            },
            {
                category: 'Ethics & Morality',
                count: 12,
                NumberofGamesPlayed: 0,
                NumberofSessions: 0,
                AverageSessionLength: 0,
                NumberofFriends: 0,
                subcategories: [
                    {
                        category: 'Metaethics',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Moral Relativism',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Moral Absolutism',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Moral Realism',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Moral Subjectivism',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Moral Objectivism',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Applied Ethics',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Bioethics',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Environmental Ethics',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Business Ethics',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Medical Ethics',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Ethical Leadership',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Descriptive Ethics',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Cultural Relativism',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Ethical Egoism',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Utilitarianism',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Virtue Ethics',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Deontological Ethics',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Virtue Ethics',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Courage',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Temperance',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Wisdom',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Justice',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Compassion',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Normative Ethics',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Consequentialism',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Contractualism',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Kantian Ethics',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Pragmatic Ethics',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Rights Ethics',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Religious Ethics',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Islamic Ethics',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Christian Ethics',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Buddhist Ethics',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Hindu Ethics',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Jewish Ethics',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Professional Ethics\n',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Medical Ethics',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Legal Ethics',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Business Ethics',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Engineering Ethics',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Journalism Ethics',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Bioethics',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Medical Research Ethics',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Reproductive Ethics',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'End-of-Life Ethics',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Genetic Engineering Ethics',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Organ Transplantation Ethics',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    }
                ]
            },
            {
                category: 'Geography & Travel',
                count: 12,
                NumberofGamesPlayed: 0,
                NumberofSessions: 0,
                AverageSessionLength: 0,
                NumberofFriends: 0,
                subcategories: [
                    {
                        category: 'Physical Geography',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Geomorphology',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Climatology',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Hydrology',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Biogeography',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Pedology',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Cultural Geography',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Cultural Landscape',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Cultural Ecology',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Ethnography',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Cultural Diffusion',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Cultural Geography Theories',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Travel Destinations',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Landmarks',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'UNESCO World Heritage Sites',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Natural Wonders',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Adventure Destinations',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Cultural Capitals',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Human Geography',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Population Geography',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Urban Geography',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Rural Geography',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Economic Geography',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Political Geography',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Geopolitics',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'International Conflict',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Diplomacy',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Geopolitical Theories',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Geopolitical Strategies',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Geopolitical Risk Analysis',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Historical Geography\n',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Landscape Change Over Time',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Historical Cartography',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Settlement Patterns',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Colonial Geography',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Geopolitical History',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Tourism Management',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Destination Marketing',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Ecotourism',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Sustainable Tourism',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Adventure Tourism',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Cultural Tourism',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Urban Geography',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Urban Planning',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Transportation Systems',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Urbanization Trends',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Gentrification',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Social Geography of Cities',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Environmental Geography',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Climate Change',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Biodiversity Conservation',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Land Use Planning',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Natural Hazards',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Environmental Impact Assessment',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    }
                ]
            },
            {
                category: 'Economics',
                count: 12,
                NumberofGamesPlayed: 0,
                NumberofSessions: 0,
                AverageSessionLength: 0,
                NumberofFriends: 0,
                subcategories: [
                    {
                        category: 'Macroeconomics',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Fiscal Policy',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Monetary Policy',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Economic Growth',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Inflation',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Unemployment',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'International Economics',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Trade Policy',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Globalization',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Trade Agreements',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Exchange Rates',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Economic Development',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Development Economics',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Poverty Reduction',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Sustainable Development',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Foreign Aid',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Income Inequality',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Human Development Index',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Behavioral Economics',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Prospect Theory',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Irrationality',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Decision Heuristics',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Neuroeconomics',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Behavioral Finance',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Environmental Economics',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Externalities',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Pollution Control',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Natural Resource Economics',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Carbon Pricing',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Sustainable Resource Management',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Financial Economics',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Asset Pricing',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Financial Markets',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Risk Management',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Corporate Finance',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Behavioral Finance',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Labor Economics',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Wage Determination',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Employment Policies',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Labor Market Dynamics',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Human Capital Development',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Gender Economics',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Industrial Organization',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Market Structure',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Pricing Strategies',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Competition Policy',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Monopoly Power',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Antitrust Regulation',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    }
                ]
            },
            {
                category: 'Political Science',
                count: 12,
                NumberofGamesPlayed: 0,
                NumberofSessions: 0,
                AverageSessionLength: 0,
                NumberofFriends: 0,
                subcategories: [
                    {
                        category: 'Political Theory',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Liberalism',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Conservatism',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Socialism',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Communism',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Anarchism',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Comparative Politics',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Democratization',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Authoritarianism',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Nationalism',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Civil Society',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Political Culture',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'International Relations',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'International Organizations',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Diplomatic Relations',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Conflict Resolution',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'War and Peace',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Human Rights',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Public Administration',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Bureaucratic Theory',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Public Policy Analysis',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Public Management',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Administrative Law',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Government Budgeting',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Public Policy',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Policy Analysis',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Policy Implementation',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Policy Evaluation',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Policy Process',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Policy Advocacy',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Political Philosophy',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Democracy',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Liberalism',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Marxism',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Conservatism',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Feminist Political Theory',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Governance',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Government Institutions',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Bureaucratic Politics',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Public Sector Management',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Governance Structures',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Administrative Law',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Political Economy\n',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Economic Policy Analysis',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Political Institutions and Economic Development',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Comparative Economic Systems',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Political Economy of Conflict',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Political Economy of International Trade',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    }
                ]
            }
        ]
    },
    {
        category: 'MEDIA & MUSIC',
        count: 120,
        NumberofGamesPlayed: 0,
        NumberofSessions: 0,
        AverageSessionLength: 0,
        NumberofFriends: 0,
        color: 'primary',
        subcategories: [
            {
                category: 'Digital Media Production',
                count: 13,
                NumberofGamesPlayed: 0,
                NumberofSessions: 0,
                AverageSessionLength: 0,
                NumberofFriends: 0,
                subcategories: [
                    {
                        category: 'Video Editing',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Software Tools',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Effects and Transitions',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Color Correction',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Audio Editing',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Rendering Techniques',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Podcasting',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Recording Techniques',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Editing and Mixing',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Scripting and Storytelling',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Distribution Platforms',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Sound Design',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Content Creation',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Graphic Design',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Animation',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Photography',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Social Media Management',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Content Strategy',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Graphic Design',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Logo Design',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Typography',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Branding and Identity',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Print Design',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Web Design',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Animation Production',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: '2D Animation',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: '3D Animation',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Motion Graphics',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Stop Motion Animation',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Visual Effects (VFX)',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Web Development',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Front-end Development',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Back-end Development',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Full-stack Development',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Responsive Design',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'User Experience (UX) Design',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Rhythm and Meter',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Harmony and Chords',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Musical Forms',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Music History',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Social Media Management',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Social Media Strategy',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Community Engagement',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Analytics and Reporting',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Content Calendar Management',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Influencer Collaboration',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Mobile App Development',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'iOS App Development',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Android App Development',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Cross-platform Development',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'User Interface (UI) Design',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Mobile App Testing and Optimization',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    }
                ]
            },
            {
                category: 'Journalism',
                count: 11,
                NumberofGamesPlayed: 0,
                NumberofSessions: 0,
                AverageSessionLength: 0,
                NumberofFriends: 0,
                subcategories: [
                    {
                        category: 'News Reporting',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Investigative Journalism',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Feature Writing',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Interviewing Techniques',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Fact-Checking',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Data Journalism',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Storytelling ',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Narrative Techniques',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Documentary Filmmaking',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Photojournalism',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Editorial Writing',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Script Writing',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Investigative Journalism',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Data Journalism',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Undercover Reporting',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Watchdog Journalism',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Documentaries',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Public Records Research',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Opinion Writing',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Editorial Columns',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Opinion Essays',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Commentary',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Op-Ed Pieces',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Blogging',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Broadcast Journalism',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'TV News Reporting',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Radio Journalism',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Podcasting',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Live Reporting',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Broadcast Production',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Data Journalism',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Data Analysis',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Data Visualization',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Database Management',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Data Ethics',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Interactive Data Storytelling',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Sports Journalism',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Game Reporting',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Athlete Profiles',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Sports Analysis',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Event Coverage',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Sports Feature Writing',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Environmental Journalism',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Climate Change Reporting',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Conservation Issues',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Environmental Policy Analysis',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Wildlife Conservation',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Sustainability Reporting',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    }
                ]
            },
            {
                category: 'Broadcasting',
                count: 10,
                NumberofGamesPlayed: 0,
                NumberofSessions: 0,
                AverageSessionLength: 0,
                NumberofFriends: 0,
                subcategories: [
                    {
                        category: 'Radio Techniques',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Scripting for Radio',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Voiceover Techniques',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Live Broadcasting',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Podcast Production',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Sound Editing',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Television Techniques',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'News Anchoring',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Studio Production',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Field Reporting',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Video Production',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Digital Broadcasting',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Online Streaming',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Podcast Production',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Webinars',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Social Media Broadcasting',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Digital Audio Platforms',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Broadcast Journalism',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'News Reporting',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Investigative Journalism',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Interview Techniques',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Storytelling',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Editorial Production',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Broadcast Management',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Programming Strategies',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Advertising Sales',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Audience Research',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Budgeting and Finance',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Regulatory Compliance',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Lighting Techniques',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Broadcast Production',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Studio Production',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Field Production',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Live Broadcasting',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Post-production Editing',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Broadcast Scriptwriting',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Broadcast Engineering',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Signal Transmission',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Broadcast Equipment Maintenance',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Satellite Communication',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'RF Engineering',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Audio-Visual Systems Integration',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Broadcast Ethics and Regulations',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Media Law and Regulations',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Broadcast Ethics Guidelines',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Content Standards and Practices',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'FCC Compliance (in the U.S.)',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Journalism Ethics in Broadcasting',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Broadcast Technology Innovation',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    }
                ]
            },
            {
                category: 'Animation',
                count: 14,
                NumberofGamesPlayed: 0,
                NumberofSessions: 0,
                AverageSessionLength: 0,
                NumberofFriends: 0,
                subcategories: [
                    {
                        category: '2D Animation',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Character Animation',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Storyboarding',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Frame-by-Frame Animation',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Background Design',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Animating Principles',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: '3D Animation',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Modeling and Texturing',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Rigging and Animation',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Lighting and Rendering',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Visual Effects',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: '3D Character Animation',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Stop Motion Animation',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Claymation',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Puppet Animation',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Cut-out Animation',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Object Animation',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Pixilation',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Motion Graphics',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Title Sequences',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Infographics',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Visual Effects (VFX)',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Kinetic Typography',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Logo Animation',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Character Animation',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Character Design',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Rigging',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Character Performance',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Lip Syncing',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Walk Cycles',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Architectural Animation',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: '3D Architectural Visualization',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Walkthrough Animation',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Interior Design Animation',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Landscape Animation',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Building Construction Animation',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Educational Animation',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Explainer Videos',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Educational Character Animation',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Scientific Visualization Animation',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Historical Animation',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Interactive Educational Animation',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Game Animation\n',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Game Character Animation',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Environment Animation',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Rigging',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Game Cutscene Animation',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Interactive Animation',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    }
                ]
            },
            {
                category: 'Graphic Design for Media',
                count: 12,
                NumberofGamesPlayed: 0,
                NumberofSessions: 0,
                AverageSessionLength: 0,
                NumberofFriends: 0,
                subcategories: [
                    {
                        category: 'Typography',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Font Selection',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Layout Design',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Type Hierarchy',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Text Effects',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Typography Trends',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Visual Design',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Color Theory',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Composition',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Image Manipulation',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Logo Design',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'User Experience Design',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Print Design',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Poster Design',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Brochure Design',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Magazine Layout',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Packaging Design',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Book Cover Design',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Digital Design',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Website Design',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'User Interface (UI) Design',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Mobile App Design',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Social Media Graphics',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Email Newsletter Design',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Branding and Identity',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Logo Design',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Brand Guidelines',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Brand Collateral',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Corporate Identity',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Brand Strategy',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Infographic Design',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Data Visualization',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Information Design',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Infographic Layout',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Interactive Infographics',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Infographic Printing',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Packaging Design',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Product Packaging',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Label Design',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Box Design',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Packaging Mockups',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Packaging Production',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'User Interface (UI) Design',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Wireframing',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'UI Prototyping',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'UI Animation',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Mobile UI Design',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Web UI Design',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    }
                ]
            },
            {
                category: 'Film Scoring',
                count: 12,
                NumberofGamesPlayed: 0,
                NumberofSessions: 0,
                AverageSessionLength: 0,
                NumberofFriends: 0,
                subcategories: [
                    {
                        category: 'Composition',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Scoring Techniques',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Orchestration',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Temp Tracks',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Syncing to Visuals',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Music Theory Application',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Production',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Recording Techniques',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Mixing and Mastering',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Sound Editing',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Session Musicians',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Orchestration',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Instrumentation',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Arranging Techniques',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Score Preparation',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'MIDI Mockups',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Conducting',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Sound Design',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Foley Artistry',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Sound Effects Creation',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Dialogue Editing',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Sound Mixing',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Audio Post-Production',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Film Analysis',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Narrative Structure',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Genre Study',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Cinematic Techniques',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Score Analysis',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Soundtrack Critique',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Post-Production ',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Music Theory and Analysis',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Harmony',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Counterpoint',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Form and Structure',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Musical Styles and Genres',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Score Analysis',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Recording Techniques',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Studio Recording',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Live Recording',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Mixing',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Editing',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Mastering',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Music Licensing and Clearance',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Copyright Law',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Licensing Agreements',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Royalty Collection',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Public Performance Rights',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Sync Licensing',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    }
                ]
            },
            {
                category: 'MEDIA & MUSIC',
                count: 12,
                NumberofGamesPlayed: 0,
                NumberofSessions: 0,
                AverageSessionLength: 0,
                NumberofFriends: 0,
                subcategories: [
                    {
                        category: 'ChatGPT',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Language Understanding',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Conversational AI',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Natural Language Generation',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Text Summarization',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Language Translation',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Music Production',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Beat Making',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Music Arrangement',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Audio Mixing',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Sound Design',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Mastering',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Video Production',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Scriptwriting',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Storyboarding',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Shooting',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Editing',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Color Grading',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Content Creation',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Social Media Content',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Blog Writing',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Podcast Production',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Graphic Design',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Copywriting',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Digital Marketing',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Social Media Marketing',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Search Engine Optimization (SEO)',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Email Marketing',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Content Marketing',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Influencer Marketing',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Podcast Production',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Scriptwriting',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Audio Recording',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Editing and Mixing',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Hosting and Distribution',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Audience Engagement',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Live Event Production',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Event Planning',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Technical Setup and Operation',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Stage Management',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Live Streaming',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Audience Interaction',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Sound Engineering',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Recording Engineering',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Mixing Engineering',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Mastering Engineering',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Audio Restoration',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Acoustics and Room Treatment',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    }
                ]
            },
            {
                category: 'Digital Marketing',
                count: 12,
                NumberofGamesPlayed: 0,
                NumberofSessions: 0,
                AverageSessionLength: 0,
                NumberofFriends: 0,
                subcategories: [
                    {
                        category: 'Content Strategy',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Content Planning',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Content Creation',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Content Distribution',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Content Optimization',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Content Curation',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Social Media Marketing',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Platform Management',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Community Engagement',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Content Scheduling',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Analytics and Reporting',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Advertising Campaigns',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Search Engine Optimization',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Keyword Research',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'On-Page Optimization',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Off-Page Optimization',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Technical SEO',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Local SEO Strategies',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Email Marketing',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Email Campaign Planning',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Email Copywriting',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Email Automation',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Email Analytics',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Email List Segmentation\n',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Influencer Marketing',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Influencer Identification',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Collaboration Negotiation',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Campaign Monitoring',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Performance Analysis',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Relationship Management',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Mobile Marketing',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Mobile App Marketing',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'SMS Marketing',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'App Store Optimization (ASO)',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Mobile Website Optimization',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Location-Based Marketing',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Video Marketing',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Video Content Creation',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'YouTube Marketing',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Video Advertising',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Live Streaming',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Video Analytics and Optimization',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Influencer Marketing',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Influencer Identification',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Campaign Strategy',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Outreach and Collaboration',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Contract Negotiation',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Performance Evaluation',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    }
                ]
            },
            {
                category: 'Music Production',
                count: 12,
                NumberofGamesPlayed: 0,
                NumberofSessions: 0,
                AverageSessionLength: 0,
                NumberofFriends: 0,
                subcategories: [
                    {
                        category: 'Recording Techniques',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Studio Setup',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Microphone Techniques',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Tracking and Overdubbing',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Editing and Compiling',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Session Management',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Music Mixing',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Track Balancing',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Equalization (EQ)',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Dynamic Processing',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Effects Processing',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Master Bus Processing',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Music Mastering',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Track Sequencing',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Audio Enhancement',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Dynamic Range Compression',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Final Quality Control',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Loudness Optimization',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Instrumentation',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Musical Instruments',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Virtual Instruments',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Live Instrumentation',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Instrument Programming',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Sample Libraries',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Music Composition',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Melody Creation',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Harmony Construction',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Rhythm Arrangement',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Structure Development',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Orchestration Techniques',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Studio Equipment and Technology',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Microphones',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Audio Interfaces',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Digital Audio Workstations (DAWs)',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Studio Monitors',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'MIDI Controllers',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Music Editing',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Audio Editing',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Timing Correction',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Pitch Correction',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Sample Editing',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Sound Effects Editing',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Music Business and Management',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Music Licensing and Copyright',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Music Publishing',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Royalties and Contracts',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Artist Management',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Budgeting and Finance',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    }
                ]
            }
        ]
    },
    {
        category: 'SCIENCE & TECHNOLOGY',
        count: 120,
        NumberofGamesPlayed: 0,
        NumberofSessions: 0,
        AverageSessionLength: 0,
        NumberofFriends: 0,
        color: 'primary',
        subcategories: [
            {
                category: 'Physics',
                count: 12,
                NumberofGamesPlayed: 0,
                NumberofSessions: 0,
                AverageSessionLength: 0,
                NumberofFriends: 0,
                subcategories: [
                    {
                        category: 'Classical Mechanics',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Newtonian Mechanics',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Lagrangian Mechanics',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Hamiltonian Mechanics',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Fluid Mechanics',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Solid Mechanics',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Electromagnetism',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Electrostatics',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Magnetostatics',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Electrodynamics',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Electromagnetic Waves',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Optics',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Thermodynamics',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Laws of Thermodynamics',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Thermodynamic Systems',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Thermodynamic Processes',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Statistical Thermodynamics',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Thermal Radiation',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Quantum Mechanics',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Wave Mechanics',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Matrix Mechanics',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Schr\u00f6dinger Equation',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Quantum Field Theory',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Quantum Entanglement',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Relativity',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Special Relativity',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'General Relativity',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Gravitational Waves',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Black Holes',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Cosmological Models',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Optics',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Geometrical Optics',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Physical Optics',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Wave Optics',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Quantum Optics',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Nonlinear Optics',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Fluid Mechanics',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Fluid Dynamics',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Fluid Statics',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Hydrodynamics',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Aerodynamics',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Turbulence',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Astrophysics',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Stellar Astrophysics',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Galactic Astrophysics',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Cosmology',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Exoplanets',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'High-Energy Astrophysics',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    }
                ]
            },
            {
                category: 'Biology',
                count: 6,
                NumberofGamesPlayed: 0,
                NumberofSessions: 0,
                AverageSessionLength: 0,
                NumberofFriends: 0,
                subcategories: [
                    {
                        category: 'Cell Biology',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Cell Structure',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Cell Function',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Cell Cycle',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Cellular Metabolism',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Cell Signaling',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Genetics',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Mendelian Genetics',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Molecular Genetics',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Population Genetics',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Genetic Engineering',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Epigenetics',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Evolutionary Biology',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Natural Selection',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Speciation',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Phylogenetics',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Evolutionary Ecology',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Human Evolution',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Ecology',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Ecosystems',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Population Ecology',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Community Ecology',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Conservation Biology',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Ecological Modeling',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Physiology',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Human Physiology',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Animal Physiology',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Plant Physiology',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Comparative Physiology',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Systems Physiology',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Microbiology',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Bacteriology',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Virology',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Mycology',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Immunology',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Microbial Ecology',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Neurobiology',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Neuroanatomy',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Neurophysiology',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Neurochemistry',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Cognitive Neuroscience',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Developmental Neurobiology',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Botany',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Plant Anatomy',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Plant Physiology',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Plant Taxonomy',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Plant Ecology',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Plant Genetics',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    }
                ]
            },
            {
                category: 'Computer Programming',
                count: 18,
                NumberofGamesPlayed: 0,
                NumberofSessions: 0,
                AverageSessionLength: 0,
                NumberofFriends: 0,
                subcategories: [
                    {
                        category: 'Web Development',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'HTML/CSS',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'JavaScript',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Backend Development',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Frontend Frameworks',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Web Security',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Mobile App Development',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Android Development',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'iOS Development',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Cross-platform Development',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Mobile UI/UX Design',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Mobile App Testing',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Software Engineering',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Software Architecture',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Software Testing',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Agile Development',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'DevOps',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Software Maintenance',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Data Structures and Algorithms',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Arrays and Linked Lists',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Trees and Graphs',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Sorting and Searching',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Algorithm Design Techniques',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Dynamic Programming',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Game Development',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Game Design',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Game Engines',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Game Physics',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Game AI',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Multiplayer Networking',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Artificial Intelligence (AI) and Machine Learning',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Machine Learning Algorithms',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Neural Networks',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Natural Language Processing (NLP)',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Computer Vision',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Reinforcement Learning',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Database Management',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Relational Databases',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'NoSQL Databases',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Database Design',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Database Administration',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Data Warehousing',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Cloud Computing',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Infrastructure as a Service (IaaS)',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Platform as a Service (PaaS)',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Software as a Service (SaaS)',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Cloud Storage',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Cloud Security',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    }
                ]
            },
            {
                category: 'Chemistry',
                count: 12,
                NumberofGamesPlayed: 0,
                NumberofSessions: 0,
                AverageSessionLength: 0,
                NumberofFriends: 0,
                subcategories: [
                    {
                        category: 'Organic Chemistry',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Hydrocarbons',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Functional Groups',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Stereochemistry',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Organic Synthesis',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Bioorganic Chemistry',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Inorganic Chemistry',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Periodic Table',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Coordination Chemistry',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Solid State Chemistry',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Main Group Chemistry',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Transition Metal Chemistry',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Physical Chemistry',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Chemical Kinetics',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Thermodynamics',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Quantum Chemistry',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Electrochemistry',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Surface Chemistry',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Analytical Chemistry',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Spectroscopy',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Chromatography',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Mass Spectrometry',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Electroanalytical Chemistry',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Analytical Instrumentation',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Biochemistry',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Proteins and Enzymes',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Nucleic Acids',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Carbohydrates',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Lipids',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Metabolic Pathways',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Environmental Chemistry',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Atmospheric Chemistry',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Water Chemistry',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Soil Chemistry',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Environmental Pollutants',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Green Chemistry',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Medicinal Chemistry',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Drug Discovery',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Pharmacology',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Pharmaceutical Chemistry',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Drug Synthesis',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Drug Delivery Systems',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Polymer Chemistry',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Polymer Synthesis',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Polymerization Mechanisms',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Polymer Characterization',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Polymer Engineering',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Biopolymers',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    }
                ]
            },
            {
                category: 'Data Science',
                count: 12,
                NumberofGamesPlayed: 0,
                NumberofSessions: 0,
                AverageSessionLength: 0,
                NumberofFriends: 0,
                subcategories: [
                    {
                        category: 'Data Analysis',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Exploratory Data Analysis',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Statistical Inference',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Regression Analysis',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Time Series Analysis',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: ' Hypothesis Testing',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Machine Learning',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Supervised Learning',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Unsupervised Learning ',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Reinforcement Learning',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Neural Networks',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: ' Deep Learning',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Big Data',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Data Storage and Processing',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Data Management',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: ' Distributed Computing',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: ' Data Visualization',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Big Data Analytics',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Data Visualization',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Charts and Graphs',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: ' Dashboards ',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Interactive Visualization ',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Geospatial Visualization',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Network Visualization',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Statistical Modeling',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Linear Regression ',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Logistic Regression ',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Decision Trees',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Random Forests ',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Support Vector Machines',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Natural Language Processing (NLP)',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Text Processing',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Sentiment Analysis',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Named Entity Recognition (NER)',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Topic Modeling',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Language Translation',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Time Series Analysis',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Time Series Forecasting',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Seasonal Decomposition',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'ARIMA Models',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Exponential Smoothing',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Time Series Anomaly Detection',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Reinforcement Learning',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Markov Decision Processes (MDPs)',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Q-Learning',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Deep Q-Networks (DQN)',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Policy Gradient Methods',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Actor-Critic Methods',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    }
                ]
            },
            {
                category: 'Astronomy',
                count: 12,
                NumberofGamesPlayed: 0,
                NumberofSessions: 0,
                AverageSessionLength: 0,
                NumberofFriends: 0,
                subcategories: [
                    {
                        category: 'Observational Astronomy',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Telescopes and Observatories',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Photometry ',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Spectroscopy ',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Astrometry',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Cosmography',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Planetary Science',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Planetary Formation',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Planetary Surfaces ',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Planetary Atmospheres ',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Planetary Interiors ',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Planetary Magnetospheres',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Stellar Astronomy',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Star Formation ',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Stellar Evolution ',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Stellar Atmospheres',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: ' Variable Stars ',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Binary Systems',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Galactic Astronomy',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Milky Way Galaxy',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Galactic Structure',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Galactic Dynamics',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Galactic Nuclei',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Galactic Evolution',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Cosmology',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Big Bang Theory ',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Cosmic Microwave Background',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Dark Matter',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Dark Energy ',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Cosmological Models',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Exoplanetary Science',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Exoplanet Detection Methods',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Exoplanet Atmospheres',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Exoplanet Habitability',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Exoplanet Formation',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Exoplanet Characterization',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'High-Energy Astrophysics',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Black Holes',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Neutron Stars',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Gamma-Ray Bursts',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'X-ray Astronomy',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Cosmic Rays',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Solar Physics',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Solar Structure and Dynamics',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Solar Magnetism',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Solar Flares and Coronal Mass Ejections (CMEs)',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Solar Wind',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Solar Activity Cycle',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    }
                ]
            },
            {
                category: 'Robotics',
                count: 12,
                NumberofGamesPlayed: 0,
                NumberofSessions: 0,
                AverageSessionLength: 0,
                NumberofFriends: 0,
                subcategories: [
                    {
                        category: 'Robot Design',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Mechanical Design',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Electrical Design ',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Sensors and Actuators ',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Kinematics ',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Dynamics',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Robotics Programming',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Robot Operating System (ROS) ',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Motion Planning',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Localization and Mapping ',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Behavior-Based Robotics ',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Reinforcement Learning',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Robotics Applications',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Industrial Robots ',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Medical Robots ',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Service Robots ',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Autonomous Vehicles',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Robotic Surgery',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Human-Robot Interaction',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Robot Perception',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Natural Language Processing ',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Human-Robot Collaboration',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: ' Robot Ethics',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: ' Social Robotics',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Robotics Ethics',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Ethical Design ',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Privacy and Security',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Legal and Social Implications',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Transparency ',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Accountability',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Control Systems',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Feedback Control',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'PID Control',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Motion Control',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Adaptive Control',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Autonomous Control',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Sensing and Perception',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Sensor Fusion',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Computer Vision',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Lidar and Radar Perception',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Object Recognition',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Environment Mapping',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Mobile Robotics',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Wheeled Robots',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Legged Robots',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Flying Robots (Drones)',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Underwater Robots (ROVs)',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Swarm Robotics',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    }
                ]
            },
            {
                category: 'Environmental Science',
                count: 12,
                NumberofGamesPlayed: 0,
                NumberofSessions: 0,
                AverageSessionLength: 0,
                NumberofFriends: 0,
                subcategories: [
                    {
                        category: 'Climate Science',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Climate Change',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: ' Global Warming ',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Greenhouse Effect',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: ' Climate Models ',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Climate Policy',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Conservation Biology',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Biodiversity Conservation ',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Habitat Conservation ',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Species Recovery ',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Conservation Genetics ',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Wildlife Management',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Environmental Policy',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Environmental Regulations',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: ' Environmental Impact Assessments ',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Sustainable Development ',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Policy Analysis ',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Environmental Governance',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Environmental Chemistry',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Pollutants and Toxicology',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Environmental Monitoring',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Water Quality',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Air Quality',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Soil Chemistry',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Sustainability Studies',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Sustainable Practices',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: ' Renewable Energy',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Waste Management',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: ' Eco-friendly Technologies',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Circular Economy',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Atmospheric Science',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Meteorology',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Air Quality Monitoring',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Climate Modeling',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Weather Forecasting',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Atmospheric Chemistry',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Ecology',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Ecosystem Dynamics',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Biodiversity Conservation',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Ecological Restoration',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Population Ecology',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Community Ecology',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Environmental Engineering',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Water Treatment',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Waste Management',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Pollution Control',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Renewable Energy Systems',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Sustainable Infrastructure',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    }
                ]
            },
            {
                category: 'Artificial Intelligence',
                count: 12,
                NumberofGamesPlayed: 0,
                NumberofSessions: 0,
                AverageSessionLength: 0,
                NumberofFriends: 0,
                subcategories: [
                    {
                        category: 'Machine Learning',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Supervised Learning',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Unsupervised Learning ',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Reinforcement Learning',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: ' Deep Learning',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Transfer Learning',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Deep Learning',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Convolutional Neural Networks ',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Recurrent Neural Networks',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Generative Adversarial Networks',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Autoencoders',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Transformers',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Computer Vision',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Object Detection',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: ' Image Classification',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: ' Image Segmentation ',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Object Tracking',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Facial Recognition',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Natural Language Processing',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Sentiment Analysis',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Named Entity Recognition',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Text Classification',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: ' Language Translation',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: ' Chatbots',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Robotics and Automation',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Robot Control ',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Autonomous Systems',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: ' Industrial Automation',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Robotic Process Automation ',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Human-Robot Collaboration',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Network Security',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Firewalls',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: ' Intrusion Detection Systems',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: ' Virtual Private Networks',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Network Access Control ',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Packet Filtering',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Reinforcement Learning',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Q-Learning',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Policy Gradient Methods',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Temporal Difference Learning',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Model-Free Methods',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Model-Based Methods',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Autonomous Systems',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Autonomous Vehicles',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'UAVs (Unmanned Aerial Vehicles)',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Autonomous Robots',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Self-Driving Cars',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Autonomous Drones',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Human-Computer Interaction',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'User Interface Design',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'User Experience (UX) Design',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Human-Centered Design',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Interaction Design',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Accessibility Design',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    }
                ]
            },
            {
                category: 'Cybersecurity',
                count: 6,
                NumberofGamesPlayed: 0,
                NumberofSessions: 0,
                AverageSessionLength: 0,
                NumberofFriends: 0,
                subcategories: [
                    {
                        category: 'Information Security',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Data Protection ',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Access Control',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Identity Management ',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Security Policies ',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Security Architecture',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Cryptography',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Encryption Algorithms',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Public Key Infrastructure ',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Digital Signatures',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: ' Cryptographic Protocols',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Cryptanalysis',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Security Operations',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Incident Response',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Security Monitoring ',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Threat Intelligence',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Vulnerability Management ',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Penetration Testing',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Security Compliance',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Regulatory Compliance',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Standards Compliance',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: ' Security Audits',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Compliance Automation',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: ' Compliance Reporting',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Network Security',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Firewall Management',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Intrusion Detection Systems (IDS)',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Virtual Private Networks (VPNs)',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Network Segmentation',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Wireless Security',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Incident Response',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Incident Detection',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Incident Analysis',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Incident Containment',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Incident Eradication',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Incident Recovery',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Ethical Hacking',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Penetration Testing',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Vulnerability Assessment',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Exploitation Techniques',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Red Team Operations',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Security Auditing',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Secure Software Development',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Secure Coding Practices',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Code Reviews',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Secure Development Lifecycle (SDLC)',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Application Security Testing',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Secure DevOps',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    }
                ]
            },
            {
                category: 'Mathematics',
                count: 18,
                NumberofGamesPlayed: 0,
                NumberofSessions: 0,
                AverageSessionLength: 0,
                NumberofFriends: 0,
                subcategories: [
                    {
                        category: 'Algebra',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Linear Equations',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Quadratic Equations',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Polynomials',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Exponents and Logarithms',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Abstract Algebra',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Geometry',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Euclidean Geometry',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Non-Euclidean Geometry',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Coordinate Geometry',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Transformational Geometry',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Geometric Constructions',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Calculus',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Differential Calculus',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Integral Calculus',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Multivariable Calculus',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Differential Equations',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Real Analysis',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Statistics',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Descriptive Statistics',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Inferential Statistics',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Probability Theory',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Regression Analysis',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Statistical Inference',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Discrete Mathematics',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Combinatorics',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Graph Theory',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Set Theory',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Number Theory',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Mathematical Logic',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Linear Algebra',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Matrices',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Vector Spaces',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Linear Transformations',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Eigenvalues and Eigenvectors',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Inner Product Spaces',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Number Theory',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Prime Numbers',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Congruences',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Diophantine Equations',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Modular Arithmetic',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Algebraic Number Theory',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Mathematical Logic',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Propositional Logic',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Predicate Logic',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Proof Techniques',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Model Theory',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Set Theory',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Applied Mathematics',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Mathematical Modeling',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Optimization',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Operations Research',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Computational Mathematics',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Actuarial Science',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Topology',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Point-Set Topology',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Algebraic Topology',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Differential Topology',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Geometric Topology',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Manifold Theory',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Complex Analysis',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Complex Functions',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Analytic Continuation',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Residue Theory',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Conformal Mappings',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Harmonic Functions',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    }
                ]
            }
        ]
    },
    {
        category: 'SPORTS & RECREATION',
        count: 120,
        NumberofGamesPlayed: 0,
        NumberofSessions: 0,
        AverageSessionLength: 0,
        NumberofFriends: 0,
        color: 'primary',
        subcategories: [
            {
                category: 'Team Sports',
                count: 12,
                NumberofGamesPlayed: 0,
                NumberofSessions: 0,
                AverageSessionLength: 0,
                NumberofFriends: 0,
                subcategories: [
                    {
                        category: 'Soccer',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Passing Drills',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Shooting Techniques',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Positional Play',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Set Piece Strategies',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Goalkeeper Training',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Basketball',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Offense Strategies',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Defense Strategies',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Shooting Techniques',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Passing Drills',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Rebounding Techniques',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Football',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Offensive Plays',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Defensive Schemes',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Quarterback Skills',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Tackling Drills',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Special Teams Training',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Baseball',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Batting Techniques',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Pitching Mechanics',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Fielding Fundamentals',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Base Running Strategies',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Catching Drills',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Volleyball',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Serving Techniques',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Setting Skills',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Spiking Techniques',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Blocking Strategies',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Digging Drills',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Rugby',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Rugby Union',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Rugby League',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Rugby Sevens',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Touch Rugby',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Beach Rugby',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Hockey',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Field Hockey',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Ice Hockey',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Roller Hockey',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Floorball',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Street Hockey',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Cricket',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Test Cricket',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'One Day Internationals (ODIs)',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Twenty20 (T20)',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Club Cricket',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Indoor Cricket',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Field Handball',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    }
                ]
            },
            {
                category: 'Individual Sports',
                count: 12,
                NumberofGamesPlayed: 0,
                NumberofSessions: 0,
                AverageSessionLength: 0,
                NumberofFriends: 0,
                subcategories: [
                    {
                        category: 'Tennis',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Serve Accuracy',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Forehand Power',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Backhand Control',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Net Play Skills',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Footwork Drills',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Golf',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Swing Mechanics',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Putting Techniques',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Course Management',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Golf Fitness',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Mental Game Strategies',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Swimming',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Freestyle Stroke',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Backstroke Technique',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Breaststroke Mechanics',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Butterfly Stroke',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Flip Turn Drills',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Track and Field',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Sprinting Techniques',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Hurdling Form',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Long Jump Fundamentals',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Shot Put Techniques',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Distance Running Strategies',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Martial Arts',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Karate Forms (Kata)',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Taekwondo Kicks',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Judo Throws',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Brazilian Jiu-Jitsu Ground Techniques',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Muay Thai Strikes',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Cycling',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Road Cycling',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Mountain Biking',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Track Cycling',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'BMX Racing',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Time Trial',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Gymnastics',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Artistic Gymnastics',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Rhythmic Gymnastics',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Trampoline Gymnastics',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Acrobatic Gymnastics',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Aerobic Gymnastics',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Skateboarding',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Street Skateboarding',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Vert Skateboarding',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Park Skateboarding',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Freestyle Skateboarding',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Longboarding',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    }
                ]
            },
            {
                category: 'Fitness & Exercise',
                count: 12,
                NumberofGamesPlayed: 0,
                NumberofSessions: 0,
                AverageSessionLength: 0,
                NumberofFriends: 0,
                subcategories: [
                    {
                        category: 'Cardiovascular Workouts',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Running Plans',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Cycling Routes',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Swimming Drills',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Jump Rope Workouts',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Aerobic Dance Routines',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Strength Training',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Weightlifting',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Bodyweight Exercises',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Resistance Band Workouts',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Powerlifting',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Circuit Training',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Flexibility and Mobility',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Stretching Routines',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Yoga Poses',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Pilates Exercises',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Foam Rolling Techniques',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Mobility Drills',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Functional Training',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Core Strengthening Exercises',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Balance Training',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Functional Movement Patterns',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Agility Drills',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Proprioceptive Exercises',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'High-Intensity Interval Training (HIIT)',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Tabata Workouts',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Sprint Intervals',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Metabolic Conditioning',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'EMOM (Every Minute on the Minute) Workouts',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'AMRAP (As Many Rounds as Possible) Workouts',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Endurance Training',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Long-Distance Running',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Cycling',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Swimming',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Rowing',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Cross-Country Skiing',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Balance and Stability Training',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Stability Ball Exercises',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Bosu Ball Exercises',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Balance Board Workouts',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Pilates Stability Exercises',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Yoga Balance Poses',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Core Strengthening',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Planks',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Crunches',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Russian Twists',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Leg Raises',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Bicycle Crunches',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    }
                ]
            },
            {
                category: 'Outdoor Adventure',
                count: 12,
                NumberofGamesPlayed: 0,
                NumberofSessions: 0,
                AverageSessionLength: 0,
                NumberofFriends: 0,
                subcategories: [
                    {
                        category: 'Hiking',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Trail Navigation Skills',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Camping Gear Essentials',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Rock Climbing Techniques',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Wilderness Survival Tips',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Leave No Trace Principles',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Camping',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Tent Setup',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Campfire Cooking',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Survival Skills',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Campsite Selection',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Leave No Trace Principles',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Rock Climbing',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Belaying Techniques',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Climbing Knots',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Anchor Building',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Lead Climbing',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Bouldering Techniques',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Kayaking',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Paddling Strokes',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Eskimo Roll',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'River Navigation',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Sea Kayaking',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Whitewater Kayaking',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Mountain Biking',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Trail Riding Techniques',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Bike Maintenance',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Downhill Riding Skills',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Cross-Country Riding Tips',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Jumping and Pumping',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Camping',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Tent Camping',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Backpacking',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Car Camping',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Survival Skills',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Campfire Cooking',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Snow Sports',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Skiing (Alpine and Nordic)',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Snowboarding',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Snowshoeing',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Ice Climbing',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Sledding/Tubing',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Water Sports',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Surfing',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Windsurfing',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Kiteboarding',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Wakeboarding',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Stand-Up Paddleboarding (SUP)',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    }
                ]
            },
            {
                category: 'Water Sports',
                count: 12,
                NumberofGamesPlayed: 0,
                NumberofSessions: 0,
                AverageSessionLength: 0,
                NumberofFriends: 0,
                subcategories: [
                    {
                        category: 'Surfing',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Paddling Techniques',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Wave Riding Fundamentals',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Surfboard Selection',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Surf Etiquette',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Ocean Safety Guidelines',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Swimming',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Freestyle Stroke',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Backstroke Technique',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Breaststroke Mechanics',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Butterfly Stroke',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Open Water Swimming',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Scuba Diving',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Dive Gear Assembly',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Underwater Navigation',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Buoyancy Control',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Dive Planning',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Marine Life Identification',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Sailing',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Basic Sail Trim',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Knot Tying',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Navigation Skills',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Man Overboard Recovery',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Racing Tactics',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Water Skiing',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Getting Up on Skis',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Slalom Skiing Techniques',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Wakeboarding',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Kneeboarding',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Trick Skiing',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Snorkeling',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Snorkeling Gear',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Reef Exploration',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Underwater Photography',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Snorkeling Techniques',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Marine Life Identification',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Kiteboarding',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Kite Control',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Board Control',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Jumping and Aerial Tricks',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Downwind Riding',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Wave Riding',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Jet Skiing',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Freestyle Jet Skiing',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Racing Jet Skiing',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Jet Ski Touring',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Jet Ski Tricks',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Jet Ski Safety',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    }
                ]
            },
            {
                category: 'Yoga & Mindfulness',
                count: 12,
                NumberofGamesPlayed: 0,
                NumberofSessions: 0,
                AverageSessionLength: 0,
                NumberofFriends: 0,
                subcategories: [
                    {
                        category: 'Breath Control',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Ujjayi Breath',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Kapalabhati Breath',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Alternate Nostril Breathing',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Bee Breath',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Nadi Shuddhi',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Meditation',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Mindfulness Meditation',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Guided Visualization',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Transcendental Meditation',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Body Scan Meditation',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Loving-Kindness Meditation',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Mindfulness Practices',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Body Scan Meditation',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Loving-Kindness Meditation',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Walking Meditation',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Mantra Meditation',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Visualization Meditation',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'yoga',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Standing Poses',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Seated Poses',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Backbends',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Forward Bends',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Inversions',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Mindful Movement',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Tai Chi',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Qigong',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Feldenkrais Method',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Pilates',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Yoga Flow Sequences',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Yoga Philosophy',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Yoga Sutras of Patanjali',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Eight Limbs of Yoga',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Bhagavad Gita',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Yoga Ethics (Yamas and Niyamas)',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Yoga Lifestyle',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Yoga Anatomy',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Muscle Engagement',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Joint Alignment',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Body Awareness',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Injury Prevention',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Modification and Adaptation',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Yoga Philosophy',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Yoga Sutras of Patanjali',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Eight Limbs of Yoga',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Bhagavad Gita',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Yoga Ethics (Yamas and Niyamas)',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Yoga Lifestyle',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    }
                ]
            },
            {
                category: 'E-sports',
                count: 12,
                NumberofGamesPlayed: 0,
                NumberofSessions: 0,
                AverageSessionLength: 0,
                NumberofFriends: 0,
                subcategories: [
                    {
                        category: 'MOBA',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'League of Legends',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Dota 2',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Heroes of the Storm',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Smite',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Mobile Legends',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'FPS',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Counter-Strike: Global Offensive',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Call of Duty',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Overwatch',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Rainbow Six Siege',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Apex Legends',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Battle Royale',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Fortnite',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: "PUBG (PlayerUnknown's Battlegrounds)",
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Apex Legends',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Call of Duty: Warzone',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Free Fire',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Fighting Games',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Street Fighter',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Tekken',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Super Smash Bros.',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Mortal Kombat',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Dragon Ball FighterZ',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Real-Time Strategy (RTS)',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'StarCraft II',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Warcraft III: Reforged',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Age of Empires IV',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Command & Conquer: Remastered',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Company of Heroes 2\n',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Sports Simulation',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Soccer Simulation',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Basketball Simulation',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Racing Simulation',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Golf Simulation',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Tennis Simulation',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Card Games',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Collectible Card Games (CCGs)',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Trading Card Games (TCGs)',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Digital Card Games',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Deck-building Games',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Card Battlers',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Fighting Games',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Traditional Fighting Games',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Platform Fighting Games',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Versus Fighting Games',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Tag Team Fighting Games',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Anime Fighting Games',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    }
                ]
            },
            {
                category: 'Dance & Movement',
                count: 12,
                NumberofGamesPlayed: 0,
                NumberofSessions: 0,
                AverageSessionLength: 0,
                NumberofFriends: 0,
                subcategories: [
                    {
                        category: 'Ballet',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Classical Ballet',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Contemporary Ballet',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Neoclassical Ballet',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Russian Ballet',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Modern Ballet',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Hip Hop',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Old School Hip Hop',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'New School Hip Hop',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Breakdancing',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Locking',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Popping',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Contemporary Dance',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Floor Work',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Improvisation',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Release Technique',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Contact Improvisation',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Cunningham Technique',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Latin Dance',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Salsa',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Bachata',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Merengue',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Cha-Cha',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Rumba',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Ballroom Dance',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Waltz',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Foxtrot',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Tango',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Viennese Waltz',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Quickstep',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Jazz Dance',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'zz Dance',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Classic Jazz',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Lyrical Jazz',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Theatre Jazz',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Street Jazz',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Jazz Funk',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Tap Dance',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Rhythm Tap',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Broadway Tap',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Hoofing',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Stomp Tap',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Soft-Shoe Tap',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Folk Dance',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Traditional Folk Dances',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Ethnic Folk Dances',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Circle Dances',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Line Dances',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Square Dances',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    }
                ]
            },
            {
                category: 'Sports Psychology',
                count: 12,
                NumberofGamesPlayed: 0,
                NumberofSessions: 0,
                AverageSessionLength: 0,
                NumberofFriends: 0,
                subcategories: [
                    {
                        category: 'Mental Toughness',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Resilience',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Focus',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Confidence',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Determination',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Adaptability',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Visualization Techniques',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Imagery',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Simulation',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Self-Talk',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Goal Setting',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Performance Routines',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Performance Anxiety Management',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Relaxation Techniques',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Breathing Exercises',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Cognitive Restructuring',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Pre-competition Routines',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Stress Inoculation Training',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Goal Setting and Motivation',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'SMART Goals',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Goal Achievement Strategies',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Intrinsic Motivation',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Extrinsic Motivation',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Goal Monitoring and Feedback',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Team Dynamics',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Communication Skills',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Leadership Development',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Conflict Resolution',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Team Cohesion',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Role Clarity',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Attention and Focus',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Concentration Techniques',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Selective Attention',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Attentional Control',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Focus Under Pressure',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Distraction Management',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Self-Confidence',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Self-Efficacy',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Positive Self-Talk',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Confidence Building Exercises',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Overcoming Self-Doubt',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Resilience Training',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Leadership Development',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Captaincy Skills',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Motivating Teammates',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Communication Skills',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Conflict Resolution',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Role Modeling',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    }
                ]
            },
            {
                category: 'Recreational Games',
                count: 12,
                NumberofGamesPlayed: 0,
                NumberofSessions: 0,
                AverageSessionLength: 0,
                NumberofFriends: 0,
                subcategories: [
                    {
                        category: 'Board Games',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Chess',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Monopoly',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Scrabble',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Catan',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Risk',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Card Games',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Poker',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'UNO',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Exploding Kittens',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Settlers of Catan: The Card Game',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Magic: The Gathering',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Outdoor Games',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Tag Games',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Capture the Flag',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Ultimate Frisbee',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Kickball',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Lawn Games ',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Tabletop Role-Playing Games (RPGs)',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Dungeons & Dragons (D&D)',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Pathfinder',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Call of Cthulhu',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Shadowrun',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Warhammer Fantasy Roleplay',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Party Games',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Charades',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Pictionary',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Taboo',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Mafia/Werewolf',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Cards Against Humanity',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Video Games',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Action Games',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Adventure Games',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Puzzle Games',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Simulation Games',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Role-Playing Games (RPGs)',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Mind Games',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Sudoku',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Crossword Puzzles',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Chess',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Scrabble',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Mahjong',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Lawn Games',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Bocce Ball',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Croquet',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Horseshoes',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Lawn Darts',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Lawn Bowling',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    }
                ]
            }
        ]
    },
    {
        category: 'THIS & THAT',
        count: 120,
        NumberofGamesPlayed: 0,
        NumberofSessions: 0,
        AverageSessionLength: 0,
        NumberofFriends: 0,
        color: 'primary',
        subcategories: [
            {
                category: 'Personal Development',
                count: 12,
                NumberofGamesPlayed: 0,
                NumberofSessions: 0,
                AverageSessionLength: 0,
                NumberofFriends: 0,
                subcategories: [
                    {
                        category: 'Self-Improvement',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Goal Setting',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Time Management',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Stress Management',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Confidence Building',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Communication Skills',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Career Development',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Professional Networking',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Resume Writing',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Interview Skills',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Job Search Strategies',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Personal Branding',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Communication Skills',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Verbal Communication',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Nonverbal Communication',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Active Listening',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Public Speaking',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Conflict Resolution',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Time Management',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Prioritization Techniques',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Goal Setting',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Task Scheduling',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Time Blocking',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Pomodoro Technique',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Emotional Intelligence',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Self-Awareness',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Self-Regulation',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Empathy',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Social Skills',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Relationship Management',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Leadership Development',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Leadership Skills',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Team Management',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Decision Making',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Conflict Resolution',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Inspirational Leadership',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Financial Literacy',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Budgeting',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Saving and Investing',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Debt Management',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Financial Planning',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Wealth Building',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Health and Wellness',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Physical Fitness',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Nutrition and Diet',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Sleep Hygiene',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Stress Management',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    }
                ]
            },
            {
                category: 'Life Hacks & Productivity',
                count: 12,
                NumberofGamesPlayed: 0,
                NumberofSessions: 0,
                AverageSessionLength: 0,
                NumberofFriends: 0,
                subcategories: [
                    {
                        category: 'Time-Saving Tips',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Organization Hacks',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Productivity Tools',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Task Automation',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Efficient Workflows',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Prioritization Techniques',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Life Efficiency',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Home Organization',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Meal Planning',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Financial Management',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Travel Hacks',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Health Hacks',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Organization Hacks',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Decluttering Techniques',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Space Optimization',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Digital Organization',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Storage Solutions',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Meal Planning Systems',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Productivity Tools',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Task Management Apps',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Note-taking Software',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Calendar Applications',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Project Management Tools',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Habit Tracking Platforms',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Task Automation',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Workflow Automation',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Email Filters and Rules',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Batch Processing',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Automated Reminders',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'IFTTT (If This Then That) Recipes',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Goal Setting and Achievement',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'SMART Goals',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Goal Visualization',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Action Planning',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Goal Tracking',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Goal Celebration',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Mindfulness and Focus',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Mindfulness Practices',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Meditation Techniques',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Attention Management',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Distraction Minimization',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Flow State Optimization',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Energy Management',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Daily Routines',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Peak Performance Strategies',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Stress Reduction Techniques',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Energy Renewal Practices',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Burnout Prevention',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    }
                ]
            },
            {
                category: 'Mindfulness & Meditation',
                count: 12,
                NumberofGamesPlayed: 0,
                NumberofSessions: 0,
                AverageSessionLength: 0,
                NumberofFriends: 0,
                subcategories: [
                    {
                        category: 'Mindfulness Practices',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Mindful Breathing',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Body Scan Meditation',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Guided Visualization',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Walking Meditation',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Mindfulness in Daily Activities',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Meditation Techniques',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Transcendental Meditation',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Loving-Kindness Meditation',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Vipassana Meditation',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Mantra Meditation',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Mindfulness Meditation',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Stress Reduction',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Stress-Relief Techniques',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Relaxation Techniques',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Mindful Movement',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Journaling for Stress Relief',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Yoga and Meditation for Stress Management',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Mindful Movement',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Yoga',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Tai Chi',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Qigong',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Walking Meditation',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Dance Meditation',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Mindful Eating',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Mindful Eating Exercises',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Food Awareness Practices',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Slow Eating Techniques',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Savoring Rituals',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Emotional Eating Strategies',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Breath Awareness',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Diaphragmatic Breathing',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Box Breathing',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Counted Breathing',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Alternate Nostril Breathing',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: '4-7-8 Breathing Technique',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Body Scan Meditation',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Progressive Muscle Relaxation',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Body Awareness',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Tension Release',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Body Sensations Exploration',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Grounding Techniques',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Nature Meditation',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Forest Bathing ',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Beach Meditation',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Mountain Meditation',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Walking Meditation in Nature',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Connecting with Elements (Earth, Water, Air, Fire)',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    }
                ]
            },
            {
                category: 'DIY Home Improvement',
                count: 12,
                NumberofGamesPlayed: 0,
                NumberofSessions: 0,
                AverageSessionLength: 0,
                NumberofFriends: 0,
                subcategories: [
                    {
                        category: 'Home Decor',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Interior Design',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Furniture Restoration',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Wall Art Ideas',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Lighting Projects',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Textile Crafts',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Home Repair',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Electrical Repairs',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Painting Tips',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Roof Repairs',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Plumbing Fixes',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Flooring Fixes',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Outdoor Projects',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Backyard Landscaping',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Garden Design',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Patio Building',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Deck Construction',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Outdoor Furniture DIY',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'DIY Crafts',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Handmade Gifts',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Scrapbooking',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Knitting & Crocheting',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Sewing Projects',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Paper Crafts',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Renovation Projects',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Kitchen Renovation',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Bathroom Remodeling',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Basement Finishing',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Attic Conversion',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Garage Makeover',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Furniture Building and Restoration',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Woodworking Projects',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Furniture Refinishing',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Upholstery Repair',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Furniture Assembly',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Furniture Upcycling',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Interior Design',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Room Layout Planning',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Color Schemes',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Lighting Design',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Wall Decor',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Window Treatments',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Gardening and Landscaping',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Garden Design',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Plant Selection and Care',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Lawn Maintenance',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Outdoor Lighting',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Patio and Deck Enhancement',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    }
                ]
            },
            {
                category: 'Financial Literacy',
                count: 12,
                NumberofGamesPlayed: 0,
                NumberofSessions: 0,
                AverageSessionLength: 0,
                NumberofFriends: 0,
                subcategories: [
                    {
                        category: 'Personal Finance',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Budgeting',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Investing',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Retirement Planning',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Debt Management',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Financial Goal Setting',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Entrepreneurship',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Starting a Business',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Business Management',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Marketing Strategies',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Business Growth',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'E-commerce Tips',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Investing',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Stocks',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Bonds',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Mutual Funds',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Exchange-Traded Funds (ETFs)',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Real Estate Investment Trusts (REITs)',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Retirement Planning',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: '401(k) Plans',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Individual Retirement Accounts (IRAs)',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Pension Plans',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Social Security Benefits',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Retirement Income Strategies',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Debt Management',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Debt Consolidation',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Credit Card Management',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Student Loan Repayment',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Mortgage Management',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Debt Snowball vs. Debt Avalanche',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Budgeting and Expense Management',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Creating a Budget',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Tracking Expenses',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Expense Reduction Strategies',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Budgeting Apps and Tools',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Emergency Fund Planning',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Financial Goal Setting',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Short-Term Financial Goals',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Long-Term Financial Goals',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'SMART Goal Setting',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Prioritizing Financial Goals',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Goal Monitoring and Adjustment',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Tax Planning and Management',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Understanding Tax Basics',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Tax Deductions and Credits',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Tax Planning Strategies',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Tax-Efficient Investing',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Tax Filing and Compliance',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    }
                ]
            },
            {
                category: 'Health & Wellness',
                count: 14,
                NumberofGamesPlayed: 0,
                NumberofSessions: 0,
                AverageSessionLength: 0,
                NumberofFriends: 0,
                subcategories: [
                    {
                        category: 'Nutrition',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Healthy Eating',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Meal Planning',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Nutritional Supplements',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Weight Management',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Cooking Techniques',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Fitness',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Exercise Routines',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Strength Training',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Cardiovascular Workouts',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Flexibility Training',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'High-Intensity Interval Training (HIIT)',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Mental Health',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Stress Management',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Anxiety Relief',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Depression Coping Strategies',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Self-Care Practices',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Mindfulness Practices\n',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Sleep Health',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Sleep Hygiene',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Sleep Disorders',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Improving Sleep Quality',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Relaxation Techniques',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Creating a Sleep Routine',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Stress Management',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Stress Reduction Techniques',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Mindfulness Practices',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Time Management Strategies',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Physical Activity for Stress Relief',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Social Support Systems',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Holistic Health',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Integrative Medicine',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Alternative Therapies (Acupuncture, Ayurveda, etc.)',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Energy Healing Practices',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Mind-Body Medicine',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Spiritual Wellness',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Preventive Healthcare',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Routine Check-ups',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Vaccinations',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Screening Tests (e.g., mammograms, colonoscopies)',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Health Risk Assessments',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Health Education and Promotion',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Chronic Disease Management',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Diabetes Management',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Hypertension Management',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Asthma Management',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Cardiovascular Disease Management',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Arthritis Management',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    }
                ]
            },
            {
                category: 'Gardening & Plant Care',
                count: 12,
                NumberofGamesPlayed: 0,
                NumberofSessions: 0,
                AverageSessionLength: 0,
                NumberofFriends: 0,
                subcategories: [
                    {
                        category: 'Plant Care',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Indoor Plant Care',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Outdoor Plant Care',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Pest Control',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Watering Techniques',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Soil Management',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Garden Design',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Vegetable Gardening',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Flower Gardening',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Container Gardening',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Herb Gardening',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Landscape Design Techniques',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Vegetable Gardening',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Raised Bed Gardening',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Container Gardening',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Companion Planting',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Crop Rotation',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Organic Pest Control',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Flower Gardening',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Annual Flowers',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Perennial Flowers',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Flower Bed Design',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Cutting Gardens',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Wildflower Gardens',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Landscape Design',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Hardscaping',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Softscaping',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Garden Pathways',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Water Features',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Garden Lighting',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Container Gardening',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Container Selection',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Soil Mixtures',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Plant Selection for Containers',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Watering and Fertilizing Containers',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Container Garden Design Ideas',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Herb Gardening',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Culinary Herbs',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Medicinal Herbs',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Indoor Herb Gardening',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Herb Garden Layouts',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Harvesting and Preserving Herbs',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Garden Maintenance',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Pruning and Trimming',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Weed Control',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Pest and Disease Management',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Mulching',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Seasonal Garden Clean-Up',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    }
                ]
            },
            {
                category: 'Hobbies & Collecting',
                count: 0,
                NumberofGamesPlayed: 0,
                NumberofSessions: 0,
                AverageSessionLength: 0,
                NumberofFriends: 0,
                subcategories: [
                    {
                        category: 'Arts & Crafts',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Painting',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Drawing',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Sculpture',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Pottery',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Jewelry Making',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Photography',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Landscape Photography',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Portrait Photography',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Wildlife Photography',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Street Photography',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Macro Photography',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Music',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Instrument Learning',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Music Composition',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Music Production',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Music Theory',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Performance Techniques',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Collecting',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Coin Collecting',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Stamp Collecting (Philately)',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Comic Book Collecting',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Antique Collecting',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Trading Card Collecting',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'DIY Projects',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Woodworking',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Sewing and Needlework',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Knitting and Crocheting',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Model Building (e.g., airplanes, cars)',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Jewelry Making',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Cooking and Baking',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Culinary Techniques',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Baking Recipes',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'International Cuisine',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Cake Decorating',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Cooking for Special Diets',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Travel and Exploration',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Adventure Travel',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Cultural Immersion',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Solo Travel',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Backpacking',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Travel Photography',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Reading and Literature',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Fiction',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Non-Fiction',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Poetry',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Book Clubs',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Literary Analysis',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    }
                ]
            },
            {
                category: 'Travel Tips & Culture',
                count: 0,
                NumberofGamesPlayed: 0,
                NumberofSessions: 0,
                AverageSessionLength: 0,
                NumberofFriends: 0,
                subcategories: [
                    {
                        category: 'Travel Planning',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Destination Research',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Accommodation Booking',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Transportation Planning',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Travel Budgeting',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Itinerary Planning',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Cultural Exploration',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Local Cuisine',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Historical Sites',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Cultural Festivals',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Traditional Arts & Crafts',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Language Immersion',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Accommodation',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Hotels',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Hostels',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Vacation Rentals (e.g., Airbnb)',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Bed and Breakfasts (B&Bs)',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Camping Sites',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Transportation',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Flights',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Trains',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Buses',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Rental Cars',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Public Transportation',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Budgeting',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Travel Expenses Tracking',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Cost-Saving Tips',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Travel Rewards Programs',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Currency Exchange',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Travel Insurance',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Local Cuisine and Dining',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Food Markets and Street Food',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Regional Specialties',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Dining Etiquette',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Cooking Classes',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Culinary Tours',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Language and Communication',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Basic Phrases and Vocabulary',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Language Learning Resources',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Translation Apps and Tools',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Cultural Communication Norms',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Language Exchange Programs',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Sightseeing and Attractions',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Landmarks and Monuments',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Museums and Galleries',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Natural Wonders',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Historical Sites',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Walking Tours',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    }
                ]
            },
            {
                category: 'Critical Thinking',
                count: 0,
                NumberofGamesPlayed: 0,
                NumberofSessions: 0,
                AverageSessionLength: 0,
                NumberofFriends: 0,
                subcategories: [
                    {
                        category: 'Problem-Solving',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Analytical Thinking',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Creative Thinking',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Critical Reading',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Decision-Making Techniques',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Logical Reasoning',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Personal Development',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Growth Mindset',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Self-Reflection',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Emotional Intelligence',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Adaptability',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Resilience',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Analytical Thinking',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Data Analysis',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Logical Reasoning',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Statistical Analysis',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Decision Trees',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Problem Decomposition',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Creative Thinking',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Brainstorming Techniques',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Lateral Thinking',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Mind Mapping',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Metaphorical Thinking',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Divergent Thinking',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Cognitive Bias Awareness',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Confirmation Bias',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Availability Heuristic',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Anchoring Bias',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Framing Effect',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Hindsight Bias',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Logical Reasoning',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Deductive Reasoning',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Inductive Reasoning',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Abductive Reasoning',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Logical Fallacies',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Propositional Logic',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Argumentation and Debate',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Argument Structure',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Counterargument Analysis',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Logical Fallacies in Arguments',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Persuasive Techniques',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Constructive Criticism',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    },
                    {
                        category: 'Decision-Making',
                        count: 0,
                        NumberofGamesPlayed: 0,
                        NumberofSessions: 0,
                        AverageSessionLength: 0,
                        NumberofFriends: 0,
                        subcategories: [
                            {
                                category: 'Decision Analysis',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Decision Criteria',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Risk Assessment',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Decision Trees',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            },
                            {
                                category: 'Ethical Decision-Making',
                                count: 0,
                                NumberofGamesPlayed: 0,
                                NumberofSessions: 0,
                                AverageSessionLength: 0,
                                NumberofFriends: 0
                            }
                        ]
                    }
                ]
            }
        ]
    }
];







// export const dailyActiveUsers = [
//   { date: "2023-05-01", views: 110 },
//   { date: "2023-05-02", views: 150 },
//   { date: "2023-05-04", views: 450 },
//   { date: "2023-05-05", views: 300 },
// ];
// // Add more daily data
// export const weeklyActiveUsers = [
//   { date: "2023-05-01", views: 120 },
//   { date: "2023-05-02", views: 120 },
//   { date: "2023-05-04", views: 250 },
//   { date: "2023-05-05", views: 350 },
// ];
// export const monthlyActiveUsers = [
//   { date: "2023-05-01", views: 120 },
//   { date: "2023-05-02", views: 140 },
//   { date: "2023-05-04", views: 250 },
//   { date: "2023-05-05", views: 300 },

//   // Add more daily data points as needed
// ];

export const pieChartData = {
    height: 350,
    type: 'pie',
    options: {
        chart: {
            id: 'device-chart'
        },
        labels: ['Android', 'IOS'],
        legend: {
            show: true,
            position: 'bottom',
            fontFamily: 'inherit',
            labels: {
                colors: 'inherit'
            }
        },
        dataLabels: {
            enabled: true,
            dropShadow: {
                enabled: false
            }
        },
        theme: {
            monochrome: {
                enabled: false // Disable monochrome theme
            }
        },
        colors: ['#D84315', '#DE623B', '#E58061', '#EB9F87'] // Set custom colors for each segment
    },
    series: [66, 50]
};

export const TopsixCategoriespieChartData = {
    height: 350,
    type: 'pie',
    options: {
        chart: {
            id: 'device-chart',
            events: {
                dataPointSelection: (event, chartContext, config) => {
                    // Get the index of the clicked pie slice
                    const selectedIndex = config.dataPointIndex;

                    // Get the label and value for the clicked slice
                    const selectedLabel = config.w.config.labels[selectedIndex];
                    const selectedValue = config.w.config.series[selectedIndex];

                    // Log or use the clicked data as needed
                    console.log('Clicked slice:', selectedLabel, 'with value:', selectedValue);
                    
                    // You can perform further actions here based on the selected data
                }
            }
        },
        labels: ['ARTS & ACTS', 'CULTURE & SOCIETY', 'MEDIA & MUSIC', 'SCIENCE & TECHNOLOGY', 'SPORTS & RECREATION', 'THIS & THAT'],
        legend: {
            show: true,
            position: 'bottom',
            fontFamily: 'inherit',
            labels: {
                colors: 'inherit'
            }
        },
        dataLabels: {
            enabled: true,
            dropShadow: {
                enabled: false
            }
        },
        theme: {
            monochrome: {
                enabled: false // Disable monochrome theme
            }
        },
        colors: ['#D84315', '#DE623B', '#E58061', '#EB9F87', '#DE623B', '#FF7043'] // Set custom colors for each segment
    },
    series: [66, 50, 40, 30, 20, 45]
};


export const dailyActiveUsers = [
    { date: '2023-05-01', users: 110 },
    { date: '2023-05-02', users: 150 },
    { date: '2023-05-04', users: 450 },
    { date: '2023-05-05', users: 300 }
];
// Add more daily data
export const weeklyActiveUsers = [
    { date: '2023-05-01', users: 120 },
    { date: '2023-05-02', users: 120 },
    { date: '2023-05-04', users: 250 },
    { date: '2023-05-05', users: 350 }
];
export const monthlyActiveUsers = [
    { date: '2023-05-01', users: 120 },
    { date: '2023-05-02', users: 140 },
    { date: '2023-05-04', users: 250 },
    { date: '2023-05-05', users: 300 }

    // Add more daily data points as needed
];
