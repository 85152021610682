import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import { getToken } from '../utils/getToken';
import React from 'react';
import ProtectedRoute from './ProtectedRoute';
import Dashboard from 'views/dashboard/Default';
import Notification from 'views/notification';
import AddNotification from 'views/notification/addNotification';
import ManageUser from 'views/user/ManageUser';
import Profile1 from 'views/user/manageUserTabs';
import NotificationHistory from 'views/notification-history';
import AdminManagement from 'views/admins-management';
// User routing
const UserPage = Loadable(lazy(() => import('views/user')));
const AnalyticsPage = Loadable(lazy(() => import('views/analytics')));
const SettingPage = Loadable(lazy(() => import('views/setting')));
const GameslistingPage = Loadable(lazy(() => import('views/games-listing')));

// Branch routing
const BranchPage = Loadable(lazy(() => import('views/branch')));
// Add User routing

// Add Branch routing
const AddBranch = Loadable(lazy(() => import('views/add-branch')));

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));

// utilities routing
const UtilsTypography = Loadable(lazy(() => import('views/utilities/Typography')));
const UtilsColor = Loadable(lazy(() => import('views/utilities/Color')));
const UtilsShadow = Loadable(lazy(() => import('views/utilities/Shadow')));
const UtilsMaterialIcons = Loadable(lazy(() => import('views/utilities/MaterialIcons')));
const UtilsTablerIcons = Loadable(lazy(() => import('views/utilities/TablerIcons')));
const AuthLogin = Loadable(lazy(() => import('views/pages/authentication/authentication3/Login3')));

// sample page routing
const SamplePage = Loadable(lazy(() => import('views/feedback')));

// order routing
const Order = Loadable(lazy(() => import('views/get-order')));

// report page routing
const ReportPage = Loadable(lazy(() => import('views/report')));
// get all order table

const OrderPage = Loadable(lazy(() => import('views/Order')));
// get all taxes
const ViewTaxes = Loadable(lazy(() => import('views/tax')));

// add tax
const AddTax = Loadable(lazy(() => import('views/add-tax')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: (
        <ProtectedRoute>
            <MainLayout />
        </ProtectedRoute>
    ),
    children: [
        {
            path: '/',
            element: <DashboardDefault />
        },
        {
            path: '/users',
            element: <UserPage />
        },

        {
            path: '/users/manage-users',
            element: <Profile1 />
        },

        {
            path: '/analytics',
            element: <AnalyticsPage />
        },
        {
            path: '/settings',
            element: <SettingPage />
        },
        {
            path: '/notification',
            element: <Notification />
        },
        {
            path: '/notification-history',
            element: <NotificationHistory />
        },

        {
            path: '/admin-management',
            element: <AdminManagement />
        },
        {
            path: 'notification/edit-notification',
            element: <AddNotification />
        },
        {
            path: '/feedback',
            element: <SamplePage />
        },
        {
            path: '/gameslisting',
            element: <GameslistingPage />
        },

        {
            path: 'users',
            element: <UserPage />
        },
        {
            path: 'addUser',
            element: <ManageUser />
        },
        {
            path: 'branch',
            element: <BranchPage />
        },

        {
            path: 'addBranch',
            element: <AddBranch />
        },

        {
            path: 'riderTracking',
            element: <SamplePage />
        },
        {
            path: 'report',
            element: <ReportPage />
        },
        {
            path: 'order',
            element: <Order />
        },
        {
            path: 'viewOrder',
            element: <OrderPage />
        },
        {
            path: 'taxes',
            element: <ViewTaxes />
        },
        {
            path: 'addTax',
            element: <AddTax />
        }
    ]
};

export default MainRoutes;
