import React, { useState, useEffect, useContext } from "react";
import TextField from "@mui/material/TextField";
import { useLocation, useNavigate } from "react-router";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  useTheme,
  Button,
  Grid,
  Typography,
} from "@mui/material";

import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";
import ImageHandler from "utils/ImageHandler";

const ManageUser = () => {
  const location = useLocation();

  const Navigate = useNavigate();

  const [user, setUser] = useState();

  const [profileImage, setProfileImage] = useState("");

  const [loading, setLoading] = useState(true);

  // const status = location?.state?.status;
  const status = "Add";

  const theme = useTheme();

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    whatsappNumber: Yup.string().required("Whatsapp Number is required"),
    email: Yup.string().email("Invalid email").required("Email is required"),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      password: "",
      whatsappNumber: "",
      email: "",
    },
    validationSchema: validationSchema,
  });
  const searchData = location?.state?.value;
  useEffect(() => {
    const assignValues = (searchData) => {
      if (searchData) {
        setUser(searchData);
        setProfileImage(searchData.profilePicture);
      }
    };

    if (searchData) {
      assignValues(searchData);
    }
  }, [user, searchData?.profilePicture]);

  useEffect(() => {
    if (user) {
      formik.setValues({
        name: user.name || "",
        password: user.password || "",
        whatsappNumber: user.whatsappNumber || "",
        email: user.email || "",
      });
    }
  }, [user]);

  const handleImageChange = async (event) => {
    const selectedImage = event.target.files[0];
    console.log(selectedImage);
    const id = toast.loading("Uploading...");
    try {
      const response = await UploadImage(selectedImage);
      if (response) {
        toast.update(id, {
          render: "Image uploaded successfully",
          type: "success",
          isLoading: false,
          autoClose: 3000,
        });
        setProfileImage(response.data.fileLocation);
      }
    } catch (error) {
      toast.update(id, {
        render: "Something went wrong. Please try again later",
        type: "error",
        isLoading: false,
        autoClose: 3000,
      });
    }
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <div
            style={{
              background: "white",
              borderRadius: "10px",
              padding: "2rem",
              marginTop: "20px",
            }}
          >
            <Typography
              variant="h2"
              sx={{
                fontFamily: "Outfit",
                fontSize: "28px",
                fontWeight: 500,
                lineHeight: "32px",
                letterSpacing: "0em",
                margin: "20px 0",
              }}
            >
              User Profile
            </Typography>
            <Grid container spacing={2}>
              {/* Profile Image Section */}
              <Grid
                item
                xs={12}
                lg={4}
                style={{
                  marginBottom: theme.breakpoints.down("lg") ? "28px" : "0",
                }}
              >
                <ImageHandler
                  profileImage={profileImage}
                  handleImageChange={handleImageChange}
                  status={status}
                />
              </Grid>

              {/* User Details Form */}
              <Grid item xs={12} lg={8}>
                <form onSubmit={formik.handleSubmit}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} lg={6}>
                      <TextField
                        id="outlined-textarea"
                        label="Name"
                        placeholder="User Name"
                        value={formik.values.name || ""}
                        onChange={formik.handleChange("name")}
                        error={
                          formik.touched.name && Boolean(formik.errors.name)
                        }
                        helperText={formik.touched.name && formik.errors.name}
                        fullWidth
                        InputProps={{
                          readOnly: status === "View", // Set readOnly based on the status
                        }}
                      />
                    </Grid>

                    {status === "Add" && (
                      <Grid item xs={12} lg={6}>
                        <TextField
                          id="outlined-password-input"
                          label="Password"
                          placeholder="Password"
                          type="password"
                          value={formik.values.password || ""}
                          onChange={formik.handleChange("password")}
                          error={
                            formik.touched.password &&
                            Boolean(formik.errors.password)
                          }
                          helperText={
                            formik.touched.password && formik.errors.password
                          }
                          fullWidth
                        />
                      </Grid>
                    )}

                    <Grid item xs={12} lg={6}>
                      <TextField
                        id="outlined-textarea-2"
                        label="Whatsapp Number"
                        placeholder="Whatsapp Number"
                        value={formik.values.whatsappNumber || ""}
                        onChange={formik.handleChange("whatsappNumber")}
                        error={
                          formik.touched.whatsappNumber &&
                          Boolean(formik.errors.whatsappNumber)
                        }
                        helperText={
                          formik.touched.whatsappNumber &&
                          formik.errors.whatsappNumber
                        }
                        fullWidth
                      />
                    </Grid>

                    <Grid item xs={12} lg={6}>
                      <TextField
                        id="outlined-textarea-3"
                        label="Email"
                        placeholder="Email..."
                        value={formik.values.email || ""}
                        onChange={formik.handleChange("email")}
                        error={
                          formik.touched.email && Boolean(formik.errors.email)
                        }
                        helperText={formik.touched.email && formik.errors.email}
                        fullWidth
                        InputProps={{
                          readOnly: status === "View", // Set readOnly based on the status
                        }}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <div
                        style={{
                          display: "flex ",
                          justifyContent: "flex-end",
                          marginTop: "40px",
                        }}
                      >
                        {status !== "View" ? (
                          <Button
                            variant="contained"
                            type="submit"
                            style={{
                              fontSize: "14px",
                              padding: "8px 12px",
                              borderRadius: "8px",
                              "&:hover": {
                                backgroundColor: "#eeeeee",
                              },
                              margin: "0 40px",
                            }}
                          >
                            {status === "Edit" ? (
                              <Typography
                                variant="h2"
                                sx={{
                                  fontFamily: "Outfit",
                                  fontSize: "14px",
                                  fontWeight: 400,
                                  lineHeight: "24px",
                                  letterSpacing: "0em",
                                  textAlign: "center",
                                  color: "white",
                                }}
                              >
                                Update User
                              </Typography>
                            ) : (
                              <Typography
                                variant="h2"
                                sx={{
                                  fontFamily: "Outfit",
                                  fontSize: "14px",
                                  fontWeight: 400,
                                  lineHeight: "24px",
                                  letterSpacing: "0em",
                                  textAlign: "center",
                                  color: "white",
                                }}
                              >
                                Add User
                              </Typography>
                            )}
                          </Button>
                        ) : null}
                      </div>
                    </Grid>
                  </Grid>
                </form>
              </Grid>
            </Grid>
          </div>
          <div
            style={{
              display: "flex",
              paddingTop: "1rem",
              justifyContent: "space-between",
            }}
          ></div>
        </Grid>
      </Grid>
    </>
  );
};

export default ManageUser;
