import React from "react";
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
} from "@mui/material";

// Constant
const headerSX = {
  "& .MuiCardHeader-action": { marginRight: 0 },
};

// Custom Main Card
const MainCard = React.forwardRef(
  (
    {
      border = false,
      boxShadow = true,
      children,
      content = true,
      contentClass = "",
      contentSX = {},
      secondary = null,
      shadow = "0 2px 14px 0 rgba(0,0,0,0.08)",
      sx = {},
      title = null,
      ...others
    },
    ref
  ) => {
    return (
      <Card
        ref={ref}
        {...others}
        sx={{
          border: border ? "1px solid" : "none",
          borderColor: border ? "1px solid #e0e0e0" : "none",
          ":hover": {
            boxShadow: boxShadow ? shadow : "inherit",
          },
          ...sx,
        }}
      >
        {/* Card header and action */}
        {title && (
          <>
            <CardHeader sx={headerSX} title={title} action={secondary} />
            <Divider />
          </>
        )}

        {/* Card content */}
        {content && (
          <CardContent sx={contentSX} className={contentClass}>
            {children}
          </CardContent>
        )}
        {!content && children}
      </Card>
    );
  }
);

export default MainCard;
