import React, { useState } from 'react';
import { Grid, Typography, Button, CircularProgress } from '@mui/material';
import NotficationCard from 'ui-component/cards/NotificationCard'; // Adjust the import path as necessary
import { useNavigate } from 'react-router';

const Notification = () => {
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [notifications, setNotifications] = useState([
        {
            id: 1,
            title: 'New Feature',
            message: 'A new feature has been added to the platform. Check it out now!',
            time: 3,
            frequency: 7,
            type: 'In-app'
        },
        {
            id: 2,
            title: 'Maintenance Notice',
            message: 'The system will be down for maintenance on Saturday from 2 AM to 4 AM.',
            time: 5,
            frequency: 30,
            type: 'Push'
        },
        {
            id: 3,
            title: 'Security Update',
            message: 'The system will be down for maintenance on Saturday from 2 AM to 4 AM.',
            time: 5,
            frequency: 30,
            type: 'Push'
        },
        {
            id: 4,
            title: 'Security Update',
            message: 'The system will be down for maintenance on Saturday from 2 AM to 4 AM.',
            time: 5,
            frequency: 30,
            type: 'Push'
        },
        {
            id: 5,
            title: 'Security Update',
            message: 'The system will be down for maintenance on Saturday from 2 AM to 4 AM.',
            time: 5,
            frequency: 30,
            type: 'Push'
        },
        {
            id: 6,
            title: 'Remainder Notification',
            message: 'You have not logged in for the last 7 days.',
            time: 7,
            frequency: 7,
            type: 'Push'
        }
    ]);

    const handleDeleteNotification = (id) => {
        setNotifications(notifications.filter((notification) => notification.id !== id));
    };

    const handleAddNotification = (newNotification) => {
        setNotifications((prevNotifications) => [...prevNotifications, newNotification]);
    };

    return (
        <>
            <Grid container justifyContent="space-between" alignItems="center" sx={{ paddingY: 3 }}>
                <Grid item>
                    <Typography variant="h3" gutterBottom sx={{ color: 'black' }}>
                        Notification Management
                    </Typography>
                </Grid>
                {/* <Grid item>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() =>
                            navigate('/notification/edit-notification', {
                                state: { status: 'add', handleAddNotification }
                            })
                        }
                    >
                        Create Notification
                    </Button>
                </Grid> */}
            </Grid>
            {loading ? (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '70vh' // Set height to full viewport height
                    }}
                >
                    <CircularProgress color="secondary" />
                </div>
            ) : (
                <Grid container spacing={2}>
                    {notifications.map((notification) => (
                        <Grid item xs={12} sm={6} md={4} key={notification.id}>
                            <NotficationCard notification={notification} onDelete={handleDeleteNotification} />
                        </Grid>
                    ))}
                </Grid>
            )}
        </>
    );
};

export default Notification;
