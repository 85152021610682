import dashboard from './dashboard';
import pages from './pages';
import utilities from './utilities';
import other from './feedback';
import gameslisting from './gameslisting';
import Report from './Report';
import user from './user';
import setting from './setting';
import analytics from './analytics';
import branch from './branch';
import feeback from './feedback';
import viewOrder from './viewOrder';
import taxViewer from './viewTax';
import notificationlisting from './notification';
import notificationHistory from './notificationHistory';
import adminManagement from './adminsManagement';
// ==============================|| MENU ITEMS ||============================== //
const menuItems = {
    items: [dashboard, analytics, user, gameslisting, feeback, notificationlisting, notificationHistory, setting, adminManagement]
    // other, branch, taxViewer, viewOrder, order, Report
};

export default menuItems;
