import PropTypes from "prop-types";
import {
  Avatar,
  CardContent,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import MainCard from "ui-component/cards/MainCard";
import { styled } from "@mui/system";

const CustomTableCell = styled(TableCell)({
  borderBottom: "1px solid gray", // gray horizontal lines
  borderLeft: "none", // remove left border
  borderRight: "none", // remove right border
});

const FriendsListings = ({ friends }) => {
  console.log("🚀 ~ FriendsListings ~ friends:", friends)
  return (
    <MainCard title="Friends">
      <CardContent>
        <TableContainer>
          <Table sx={{ minWidth: 560 }}>
            <TableHead>
              <TableRow>
                <CustomTableCell sx={{ pl: 3 }}>No#</CustomTableCell>
                <CustomTableCell>Name</CustomTableCell>
                <CustomTableCell sx={{ pr: 3 }}>Phone Number</CustomTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {friends?.map((row, index) => (
                <TableRow hover key={index}>
                  <CustomTableCell sx={{ pl: 3 }}>
                    <Typography
                      align="left"
                      component="div"
                      variant="subtitle2"
                    >
                      {index + 1}
                    </Typography>
                  </CustomTableCell>
                  <CustomTableCell>
                    <Grid
                      container
                      spacing={2}
                      alignItems="center"
                      sx={{ flexWrap: "nowrap" }}
                    >
                      <Grid item>
                        <Avatar alt="User Avatar" src={row?.avatar} />
                      </Grid>
                      <Grid item xs zeroMinWidth>
                        <Typography
                          component="div"
                          align="left"
                          variant="subtitle2"
                        >
                          {row.userName}
                        </Typography>
                      </Grid>
                    </Grid>
                  </CustomTableCell>
                  <CustomTableCell sx={{ pr: 3 }}>
                    <Typography
                      align="left"
                      component="div"
                      variant="subtitle2"
                    >
                      {row.phoneNumber}
                    </Typography>
                  </CustomTableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
    </MainCard>
  );
};

FriendsListings.propTypes = {
  friends: PropTypes.arrayOf(
    PropTypes.shape({
      avatar: PropTypes.string,
      userName: PropTypes.string,
      phoneNumber: PropTypes.string,
    })
  ).isRequired,
};

export default FriendsListings;
