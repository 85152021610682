// assets
import { IconBrandChrome, IconHelp, IconUser } from "@tabler/icons";
import AssessmentOutlinedIcon from "@mui/icons-material/AssessmentOutlined";

// constant
const icons = { IconBrandChrome, IconHelp, IconUser };

// ==============================|| USER PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const Report = {
  id: "user-docs-roadmap-4",
  type: "group",
  children: [
    {
      id: "report",
      title: "Report",
      type: "item",
      url: "/report",
      icon: AssessmentOutlinedIcon,
      breadcrumbs: false,
    },
  ],
};

export default Report;
