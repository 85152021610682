// assets
import { IconBrandChrome, IconHelp, IconUser } from "@tabler/icons";
import CribOutlinedIcon from "@mui/icons-material/CribOutlined";
import AttachMoneyOutlinedIcon from "@mui/icons-material/AttachMoneyOutlined";
// constant
const icons = { IconBrandChrome, IconHelp, IconUser };

// ==============================|| USER PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const branch = {
  id: "user-docs-roadmap-7",
  type: "group",
  children: [
    {
      id: "tax",
      title: "Taxes",
      type: "item",
      url: "/taxes",
      icon: AttachMoneyOutlinedIcon,
      breadcrumbs: false,
    },
  ],
};

export default branch;
