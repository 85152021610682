import { useEffect, useState, useContext } from 'react';

// material-ui
import { Grid } from '@mui/material';

// project imports
import EarningCard from './EarningCard';
import PopularCard from './PopularCard';
import TotalOrderLineChartCard from './TotalOrderLineChartCard';
import TotalIncomeDarkCard from './TotalIncomeDarkCard';
import TotalIncomeLightCard from './TotalIncomeLightCard';
import SupervisorAccountTwoToneIcon from '@mui/icons-material/SupervisorAccountTwoTone';
import GroupAddTwoToneIcon from '@mui/icons-material/GroupAddTwoTone';
import GroupRemoveTwoToneIcon from '@mui/icons-material/GroupRemoveTwoTone';
import SportsEsportsTwoToneIcon from '@mui/icons-material/SportsEsportsTwoTone';
import PeopleTwoToneIcon from '@mui/icons-material/PeopleTwoTone';
import FileUploadTwoToneIcon from '@mui/icons-material/FileUploadTwoTone';
import FileDownloadTwoToneIcon from '@mui/icons-material/FileDownloadTwoTone';
import GroupTwoToneIcon from '@mui/icons-material/GroupTwoTone';
import { gridSpacing } from 'store/constants/constant';
import BarChart from 'ui-component/graphs/BarCharts';
import CustomBarChart from './CustomBarChart';
import { Registration, statusOptions } from 'utils/constants';
import ReportCard from 'ui-component/graphs/ReportCard';
import LineGraph from 'ui-component/graphs/LineGraph';
import { Line } from 'react-chartjs-2';
import LineChart from 'ui-component/graphs/dashboardLineGraph';
import PieChart from 'ui-component/graphs/PieChart';
import { Installation, userGrowth } from 'utils/constants';
import { DateContext } from 'context/DataSelectorContext';
import {
    dailyActiveUsers,
    monthlyActiveUsers,
    monthlyViewsData,
    pieChartData,
    weeklyActiveUsers,
    yearlyViewsData,
    weeklyViewsData
} from 'utils/constants';
import { getAnalyticsData } from 'services/api/Analyrics';

// ==============================|| DEFAULT DASHBOARD ||============================== //

const Dashboard = () => {
    const { selectedDates, setSelectedDates } = useContext(DateContext);
    const [isLoading, setLoading] = useState(true);
    const [totalViews, setTotalViews] = useState(0);
    const [topActivities, setTopActivities] = useState([]);
    const [activeUsers, setActiveUsers] = useState([]);

    const installVsInstall = [
        { name: 'Jan', Installs: 135, UnInstalls: 35 },
        { name: 'Feb', Installs: 125, UnInstalls: 15 },
        { name: 'Mar', Installs: 135, UnInstalls: 15 },
        { name: 'Apr', Installs: 25, UnInstalls: 35 },
        { name: 'May', Installs: 75, UnInstalls: 65 },
        { name: 'Jun', Installs: 80, UnInstalls: 40 },
        { name: 'Jul', Installs: 95, UnInstalls: 80 },
        { name: 'Aug', Installs: 70, UnInstalls: 25 },
        { name: 'Sep', Installs: 95, UnInstalls: 15 },
        { name: 'Oct', Installs: 85, UnInstalls: 85 },
        { name: 'Nov', Installs: 65, UnInstalls: 25 },
        { name: 'Dec', Installs: 65, UnInstalls: 75 }
    ];
    const users = [
        { name: 'Jan', users: 135 },
        { name: 'Feb', users: 125 },
        { name: 'Mar', users: 135 },
        { name: 'Apr', users: 25 },
        { name: 'May', users: 75 },
        { name: 'Jun', users: 80 },
        { name: 'Jul', users: 95 },
        { name: 'Aug', users: 70 },
        { name: 'Sep', users: 95 },
        { name: 'Oct', users: 85 },
        { name: 'Nov', users: 65 },
        { name: 'Dec', users: 65 }
    ];

    const ViewsData = {
        weekly: weeklyViewsData,
        monthly: monthlyViewsData,
        yearly: yearlyViewsData
    };

    // const labels = {
    //     views: 'Views',
    //     downloads: 'Downloads'
    // };

    const test = {
        daily: dailyActiveUsers,
        monthly: monthlyActiveUsers,
        weekly: weeklyActiveUsers,
        Yearly: yearlyViewsData
    };

    const transformData = (data) => {
        return data.map((item) => ({
            date: formatDate(item.date), // Transform date format
            users: item.active_users
        }));
    };

    // Function to format date from YYYYMMDD to YYYY-MM-DD
    const formatDate = (dateString) => {
        const year = dateString.slice(0, 4);
        const month = dateString.slice(4, 6);
        const day = dateString.slice(6, 8);
        return `${year}-${month}-${day}`;
    };

    async function fetchAnalytics(selectedDates) {
        try {
            const response = await getAnalyticsData(selectedDates[0], selectedDates[1]);
            if (response.status === 200) {
                const {
                    analyticsData,
                    viewsData,
                    usersByDeviceData,
                    usersByLocationData,
                    growthRateData,
                    activeUsersData,
                    topUserActivitiesData
                } = response?.data;

                const transformedData = transformData(activeUsersData);
                const ActiveUsersData = {
                    Yearly: transformedData,
                    monthly: transformedData,
                    weekly: transformedData
                };

                setTotalViews(viewsData);
                setTopActivities(topUserActivitiesData);
                setActiveUsers(ActiveUsersData);
                console.log('Analytics Data:', analyticsData);
                console.log('+++++++++++', topUserActivitiesData);
                console.log('Users by Device Data:', usersByDeviceData);
                console.log('Users by Location Data:', usersByLocationData);
                console.log('Growth Rate Data:', growthRateData);
            }
        } catch (error) {
            // Handle error
            console.error('Error fetching users:', error);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        // setLoading(true);
        fetchAnalytics(selectedDates);
        setLoading(false);

        // No dependencies, so this effect will run once after the initial render
    }, [selectedDates]);

    const data = [
        { name: 'Jan', Registration: 35 },
        { name: 'Feb', Registration: 15 },
        { name: 'Mar', Registration: 15 },
        { name: 'Apr', Registration: 35 },
        { name: 'May', Registration: 65 },
        { name: 'Jun', Registration: 40 },
        { name: 'Jul', Registration: 80 },
        { name: 'Aug', Registration: 25 },
        { name: 'Sep', Registration: 15 },
        { name: 'Oct', Registration: 85 },
        { name: 'Nov', Registration: 25 },
        { name: 'Dec', Registration: 75 }
    ];

    const labels = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December'
    ];

    const datasets = [
        {
            label: 'New Users/Day',
            data: [5, 7, 15, 17, 4, 8, 20, 25, 26, 18, 27, 45],
            backgroundColor: 'rgba(153, 102, 255, 0.2)',
            borderColor: 'rgba(153, 102, 255, 1)'
        },
        {
            label: 'Total Number of Users',
            data: [5, 12, 27, 44, 48, 56, 76, 101, 127, 159, 186, 231],
            backgroundColor: 'rgba(255, 159, 64, 0.2)',
            borderColor: 'rgba(255, 159, 64, 1)'
        }
    ];

    const pieChartData = {
        height: 450,
        type: 'pie',
        options: {
            chart: {
                id: 'device-chart'
            },
            labels: ['Arts and Acts', 'Sports & Recreation', 'Culture & Society', 'Media & Music', 'Science & Technology', 'This & That'],
            legend: {
                show: true,
                position: 'bottom',
                fontFamily: 'inherit',
                labels: {
                    colors: 'inherit'
                }
            },
            dataLabels: {
                enabled: true,
                dropShadow: {
                    enabled: false
                }
            },
            theme: {
                monochrome: {
                    enabled: false // Disable monochrome theme
                }
            },
            colors: ['#ad2900', '#d63604', '#ff3c00', '#ff5724', '#ff6738', '#ff7b52'] // Set custom colors for each segment
        },
        series: [66, 50, 40, 45, 30, 25] // Corresponding data values for the new labels
    };

    const getCardProps = (primary) => {
        const isPrimaryGreaterThan500 = Number(primary) > 500;

        return {
            color: isPrimaryGreaterThan500 ? 'green' : 'red',
            iconPrimary: isPrimaryGreaterThan500 ? FileUploadTwoToneIcon : FileDownloadTwoToneIcon
        };
    };

    return (
        <Grid container spacing={2}>
            {/* <Grid item xs={12}>
                <Grid container spacing={gridSpacing}>
                    <Grid item lg={4} md={6} sm={6} xs={12}>
                        <EarningCard isLoading={isLoading} />
                    </Grid>
                    <Grid item lg={4} md={6} sm={6} xs={12}>
                        <TotalOrderLineChartCard isLoading={isLoading} />
                    </Grid>
                    <Grid item lg={4} md={12} sm={12} xs={12}>
                        <Grid container spacing={gridSpacing}>
                            <Grid item sm={6} xs={12} md={6} lg={12}>
                                <TotalIncomeDarkCard isLoading={isLoading} />
                            </Grid>
                            <Grid item sm={6} xs={12} md={6} lg={12}>
                                <TotalIncomeLightCard isLoading={isLoading} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid> */}
            <Grid item xs={12} lg={3} sm={6}>
                <ReportCard primary="188" secondary="Daily Active users" {...getCardProps('188')} />
            </Grid>
            <Grid item xs={12} lg={3} sm={6}>
                <ReportCard primary="2900" secondary="Monthly Active Users" {...getCardProps('2900')} />
            </Grid>
            <Grid item xs={12} lg={3} sm={6}>
                <ReportCard primary="145" secondary="Daily Games played" color={'#90CAF9'} iconPrimary={SportsEsportsTwoToneIcon} />
            </Grid>

            <Grid item xs={12} lg={3} sm={6}>
                <ReportCard primary="500" secondary="Monthly Games played" color={'red'} iconPrimary={SportsEsportsTwoToneIcon} />
            </Grid>
            <Grid item xs={12} lg={3} sm={6}>
                <ReportCard primary="14500" secondary="Total Active Users" color={'#90CAF9'} iconPrimary={GroupAddTwoToneIcon} />
            </Grid>
            <Grid item xs={12} lg={3} sm={6}>
                <ReportCard primary="9200" secondary="Total Inactive Users" color={'#00C853'} iconPrimary={GroupTwoToneIcon} />
            </Grid>
            <Grid item xs={12} lg={3} sm={6}>
                <ReportCard primary="5300" secondary="Total Deleted Users" color={'red'} iconPrimary={GroupRemoveTwoToneIcon} />
            </Grid>
            <Grid item xs={12} lg={3} sm={6}>
                <ReportCard primary="530000" secondary="Total Number of Users" color={'orange'} iconPrimary={PeopleTwoToneIcon} />
            </Grid>
            {/* <Grid item xs={12} lg={6} sm={6}>
                <ReportCard primary="5300" secondary="Total Deleted Users" color={'red'} iconPrimary={GroupRemoveTwoToneIcon} />
            </Grid>
            <Grid item xs={12} lg={6} sm={6}>
                <ReportCard primary="530000" secondary="Total Number of Users" color={'orange'} iconPrimary={PeopleTwoToneIcon} />
            </Grid> */}

            <Grid item xs={12}></Grid>

            <Grid item xs={12}>
                <Grid container spacing={gridSpacing}>
                    <Grid item xs={12} md={12}>
                        {/* <CustomBarChart
                            isLoading={false}
                            title="Total Growth"
                            total={2324}
                            data={data}
                            dataKeys={Registration}
                            statusOptions={statusOptions}
                        /> */}
                        <LineChart labels={labels} datasets={datasets} />
                    </Grid>

                    {/* <Grid item xs={12} md={8}>
            <BarChart />
          </Grid> */}
                    {/* <Grid item xs={12} md={4}>
                        <PopularCard isLoading={isLoading} />
                        <PieChart chartData={pieChartData} heading={'Game categories'} />
                    </Grid> */}
                    <Grid item xs={12}>
                        <LineGraph
                            initialTimeRange="weekly"
                            timeRangeOptions={['weekly', 'monthly', 'yearly']}
                            data={ViewsData}
                            title="App Store Metrics"
                            labels={labels}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <CustomBarChart
                            isLoading={false}
                            title="Installs vs. Uninstalls"
                            data={installVsInstall}
                            dataKeys={Installation}
                            statusOptions={statusOptions}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <CustomBarChart
                            isLoading={false}
                            title="New Registrations"
                            data={data}
                            dataKeys={userGrowth}
                            statusOptions={statusOptions}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default Dashboard;
