import React from 'react';
import { IconBrandChrome, IconHelp, IconUser } from '@tabler/icons';
import SettingsIcon from '@mui/icons-material/Settings';
// constant
const icons = { IconBrandChrome, IconHelp, IconUser };

const setting = {
    id: 'setting',
    type: 'group',
    children: [
        {
            id: 'settings',
            title: 'Settings',
            type: 'item',
            url: '/settings',
            icon: SettingsIcon,
            breadcrumbs: false
        }
    ]
};

export default setting;
