// material-ui
import { Typography } from '@mui/material';
import React, { useContext } from 'react';
import AppContext from '../../../../context/AppContext';

// project imports
import NavGroup from './NavGroup';
import menuItem from 'menu-items';
// ==============================|| SIDEBAR MENU LIST ||============================== //

const MenuList = () => {
    const { userInfo, user } = useContext(AppContext);

    const navItems =
        user?.UserRole === 'dispatcher'
            ? menuItem.items
                  .filter((item) => item.children[0].title !== 'Taxes')
                  .filter((item) => item.children[0].title !== 'Branches')
                  .map((item) => {
                      switch (item.type) {
                          case 'group':
                              return <NavGroup key={item.id} item={item} />;
                          default:
                              return (
                                  <Typography key={item.id} variant="h6" color="error" align="center">
                                      Menu Items Error
                                  </Typography>
                              );
                      }
                  })
            : menuItem.items.map((item) => {
                  switch (item.type) {
                      case 'group':
                          return <NavGroup key={item.id} item={item} />;
                      default:
                          return (
                              <Typography key={item.id} variant="h6" color="error" align="center">
                                  Menu Items Error
                              </Typography>
                          );
                  }
              });

    return (
        <>
            <h3
                style={{
                    marginLeft: '18px',
                    fontSize: '19px',
                    color: 'rgb(54, 65, 82)'
                }}
            >
                {' '}
                {userInfo?.firstName ? userInfo?.firstName.charAt(0).toUpperCase() + userInfo?.firstName.slice(1) : ''}{' '}
                {userInfo?.lastName ? userInfo?.lastName.charAt(0).toUpperCase() + userInfo?.lastName.slice(1) : ''}{' '}
            </h3>
            <div>{navItems}</div>{' '}
        </>
    );
};

export default MenuList;
