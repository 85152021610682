import { createRoot } from 'react-dom/client';
// third party
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import React, { useEffect } from 'react';
// project imports
import * as serviceWorker from 'serviceWorker';
import App from 'App';
import { store } from 'store';
import AppProvider from 'context/AppProvider';
// style + assets
import 'assets/scss/style.scss';
import config from './config';

// ==============================|| REACT DOM RENDER  ||============================== //

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript

root.render(
    <AppProvider>
        <Provider store={store}>
            <BrowserRouter basename={config.basename}>
                <App />
            </BrowserRouter>
        </Provider>
    </AppProvider>
);

serviceWorker.unregister();
