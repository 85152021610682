import { IconBrandChrome, IconHelp } from '@tabler/icons';
import HistoryIcon from '@mui/icons-material/History'; // Use an appropriate icon
const icons = { IconBrandChrome, IconHelp };

const notificationHistory = {
    id: 'notification-history',
    type: 'group',
    children: [
        {
            id: 'notificationHistory',
            title: 'Notification History',
            type: 'item',
            url: '/notification-history', // Adjust URL as needed
            icon: HistoryIcon,
            breadcrumbs: false
        }
    ]
};

export default notificationHistory;
