import { IconBrandChrome, IconHelp } from "@tabler/icons";
import GamesIcon from "@mui/icons-material/Games"; // constant
const icons = { IconBrandChrome, IconHelp };

const gameslisting = {
  id: "sample-docs-roadmap-2",
  type: "group",
  children: [
    {
      id: "gameslisting",
      title: "Games Management",
      type: "item",
      url: "/gameslisting",
      icon: GamesIcon,
      breadcrumbs: false,
    },
  ],
};

export default gameslisting;
