import { Grid, Typography } from '@mui/material';
import Chart from 'react-apexcharts';
import MainCard from './main';
import { useRef } from 'react';

const PieChart = ({  ref, chartData, heading }) => {
    console.log("🚀 ~ PieChart ~ heading:", heading)
    console.log("🚀 ~ PieChart ~ chartData:", chartData)
    
    return (
        <MainCard>
            <Grid container direction="column" spacing={1}>
                <Grid item>
                    <Typography variant="subtitle1">{heading}</Typography>
                </Grid>
                <Grid
                    item
                    sx={{
                        '& .apexcharts-legend-text': {
                            marginRight: '3px'
                        }
                    }}
                >
                     <Chart ref={ref}  id="chart1"  {...chartData} />
                </Grid>
            </Grid>
        </MainCard>
    );
};

export default PieChart;
