import React, { useState, useEffect } from 'react';
import { Grid, Typography, CircularProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { format } from 'date-fns'; // Optional: for date formatting

const NotificationHistory = () => {
    const [loading, setLoading] = useState(false);
    const [notifications, setNotifications] = useState([
        {
            id: 1,
            title: 'New Feature',
            type: 'In-app',
            createdDate: new Date('2024-01-01'),
            creator: 'Alice Johnson'
        },
        {
            id: 2,
            title: 'Maintenance Notice',
            type: 'Push',
            createdDate: new Date('2024-02-15'),
            creator: 'Bob Smith'
        },
        {
            id: 3,
            title: 'Security Update',
            type: 'Push',
            createdDate: new Date('2024-03-20'),
            creator: 'Charlie Brown'
        },
        {
            id: 4,
            title: 'System Upgrade',
            type: 'In-app',
            createdDate: new Date('2024-04-10'),
            creator: 'David Williams'
        },
        {
            id: 5,
            title: 'Feature Improvement',
            type: 'Push',
            createdDate: new Date('2024-05-25'),
            creator: 'Eve Thompson'
        },
        {
            id: 6,
            title: 'Reminder Notification',
            type: 'Push',
            createdDate: new Date('2024-08-18'),
            creator: 'Frank Martin'
        }
        // Add more notifications as needed
    ]);

    // Optionally, you could fetch notifications from an API
    useEffect(() => {
        setLoading(true);
        // Simulate a network request
        setTimeout(() => {
            setLoading(false);
        }, 1000); // Adjust time as needed
    }, []);

    return (
        <>
            <Grid container justifyContent="center" alignItems="center" sx={{ paddingY: 3 }}>
                <Grid item xs={12}>
                    <Typography variant="h3" gutterBottom sx={{ color: 'black', textAlign: 'center' }}>
                        Notification History
                    </Typography>
                </Grid>
            </Grid>
            {loading ? (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '70vh' // Set height to full viewport height
                    }}
                >
                    <CircularProgress color="secondary" />
                </div>
            ) : (
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <strong>Title</strong>
                                </TableCell>
                                <TableCell>
                                    <strong>Type</strong>
                                </TableCell>
                                <TableCell>
                                    <strong>Created Date</strong>
                                </TableCell>
                                <TableCell>
                                    <strong>Creator</strong>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {notifications.map((notification) => (
                                <TableRow key={notification.id}>
                                    <TableCell>{notification.title}</TableCell>
                                    <TableCell>{notification.type}</TableCell>
                                    <TableCell>{format(notification.createdDate, 'yyyy-MM-dd')}</TableCell>
                                    <TableCell>{notification.creator}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
        </>
    );
};

export default NotificationHistory;
