import React, { createContext, useState } from "react";

const DateContext = createContext();

const DateProvider = ({ children }) => {
  const [selectedDates, setSelectedDates] = useState([]);

  return (
    <DateContext.Provider value={{ selectedDates, setSelectedDates }}>
      {children}
    </DateContext.Provider>
  );
};

export { DateProvider, DateContext };
