import { useSelector } from "react-redux";
import { ThemeProvider } from "@mui/material/styles";
import { CssBaseline, StyledEngineProvider } from "@mui/material";
import { ApolloProvider } from "@apollo/client";
import { useDispatch } from "react-redux";
import { setToken } from "./store/index";
import AppContext from "./context/AppContext";
import { useEffect, useState, useContext } from "react";
// routing
import Routes from "routes";

// defaultTheme
import themes from "themes";
import client from "./apolloClient";
// project imports
import NavigationScroll from "layout/NavigationScroll";
import { ContentPasteOffOutlined } from "@mui/icons-material";
import OneSignalReact from "react-onesignal";
import Notificaction from "utils/toastNotification";
import { DateProvider } from "context/DataSelectorContext";

// ==============================|| APP ||============================== //

const App = () => {
  const dispatch = useDispatch();
  const [initialized, setInitialized] = useState(false);
  const { counter } = useContext(AppContext);
  const customization = useSelector((state) => state.customization);
  const { token } = useSelector((state) => state.token);
  useEffect(() => {
    let token = localStorage.getItem("token");
    let accessToken = {
      token,
    };
    dispatch(setToken(accessToken));
  }, []);

  useEffect(() => {
    let externalUserId = localStorage.getItem("user"); // You will supply the external user id to the OneSignal SDK
    externalUserId = JSON.parse(externalUserId);
    if (externalUserId?._id) {
      OneSignalReact.init({ appId: "e29f05c4-f085-4576-96e0-a170f82cbf67" });
      console.log("External userId in app.js:", externalUserId._id);
      OneSignalReact.on("initialized", function () {
        OneSignalReact.setExternalUserId(externalUserId?._id);
      });
    }
  }, [counter]);

  return (
    // <ApolloProvider client={client}>
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={themes(customization)}>
        <DateProvider>
          <CssBaseline />
          <NavigationScroll>
            <Routes />
            <Notificaction />
          </NavigationScroll>
        </DateProvider>
      </ThemeProvider>
    </StyledEngineProvider>
    // </ApolloProvider>
  );
};

export default App;
