import React, { useState, useEffect } from 'react';
import {
    Grid,
    Typography,
    CircularProgress,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Avatar,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Box,
    Alert
} from '@mui/material';

// Dummy data
const initialAdminData = [
    {
        id: 1,
        name: 'Alice Johnson',
        email: 'alice.johnson@example.com',
        phone: '+1234567890',
        location: 'New York, USA',
        image: 'https://via.placeholder.com/40' // Dummy image URL
    },
    {
        id: 2,
        name: 'Bob Smith',
        email: 'bob.smith@example.com',
        phone: '+0987654321',
        location: 'Los Angeles, USA',
        image: 'https://via.placeholder.com/40' // Dummy image URL
    },
    {
        id: 3,
        name: 'Charlie Brown',
        email: 'charlie.brown@example.com',
        phone: '+1122334455',
        location: 'Chicago, USA',
        image: 'https://via.placeholder.com/40' // Dummy image URL
    },
    {
        id: 4,
        name: 'David Williams',
        email: 'david.williams@example.com',
        phone: '+5566778899',
        location: 'Houston, USA',
        image: 'https://via.placeholder.com/40' // Dummy image URL
    },
    {
        id: 5,
        name: 'Eve Thompson',
        email: 'eve.thompson@example.com',
        phone: '+6655443322',
        location: 'Phoenix, USA',
        image: 'https://via.placeholder.com/40' // Dummy image URL
    }
];

const AdminManagement = () => {
    const [loading, setLoading] = useState(false);
    const [admins, setAdmins] = useState(initialAdminData);
    const [open, setOpen] = useState(false);
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        location: '',
        password: ''
    });
    const [errors, setErrors] = useState({});

    useEffect(() => {
        setLoading(true);
        // Simulate a network request
        setTimeout(() => {
            setLoading(false);
        }, 1000); // Adjust time as needed
    }, []);

    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
        setErrors({});
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const validateForm = () => {
        const newErrors = {};
        for (const key in formData) {
            if (!formData[key]) {
                newErrors[key] = 'This field is required';
            }
        }
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleAdd = () => {
        if (validateForm()) {
            setAdmins([...admins, { id: admins.length + 1, ...formData, image: 'https://via.placeholder.com/40' }]);
            handleClose();
        }
    };

    return (
        <>
            <Grid container justifyContent="center" alignItems="center" sx={{ paddingY: 3 }}>
                <Grid item xs={12}>
                    <Typography variant="h3" gutterBottom sx={{ color: 'black', textAlign: 'center' }}>
                        Admin Management
                    </Typography>
                </Grid>
                <Grid item xs={12} sx={{ position: 'relative' }}>
                    <Box sx={{ position: 'absolute', right: 0, top: 0, padding: 2 }}>
                        <Button variant="contained" color="primary" onClick={handleOpen}>
                            Add Admin
                        </Button>
                    </Box>
                </Grid>
            </Grid>
            {loading ? (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '70vh' // Set height to full viewport height
                    }}
                >
                    <CircularProgress color="secondary" />
                </div>
            ) : (
                <TableContainer component={Paper} sx={{ marginTop: 5 }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <strong>Image</strong>
                                </TableCell>
                                <TableCell>
                                    <strong>Name</strong>
                                </TableCell>
                                <TableCell>
                                    <strong>Email</strong>
                                </TableCell>
                                <TableCell>
                                    <strong>Phone</strong>
                                </TableCell>
                                <TableCell>
                                    <strong>Location</strong>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {admins.map((admin) => (
                                <TableRow key={admin.id}>
                                    <TableCell>
                                        <Avatar src={admin.image} alt={admin.name} />
                                    </TableCell>
                                    <TableCell>{admin.name}</TableCell>
                                    <TableCell>{admin.email}</TableCell>
                                    <TableCell>{admin.phone}</TableCell>
                                    <TableCell>{admin.location}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Add New Admin</DialogTitle>
                <DialogContent>
                    <Box component="form" noValidate autoComplete="off">
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            label="Name"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            error={Boolean(errors.name)}
                            helperText={errors.name}
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            label="Email"
                            type="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            error={Boolean(errors.email)}
                            helperText={errors.email}
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            label="Phone Number"
                            name="phone"
                            value={formData.phone}
                            onChange={handleChange}
                            error={Boolean(errors.phone)}
                            helperText={errors.phone}
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            label="Location"
                            name="location"
                            value={formData.location}
                            onChange={handleChange}
                            error={Boolean(errors.location)}
                            helperText={errors.location}
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            label="Password"
                            type="password"
                            name="password"
                            value={formData.password}
                            onChange={handleChange}
                            error={Boolean(errors.password)}
                            helperText={errors.password}
                        />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={handleAdd}>Add</Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default AdminManagement;
