// import React, { useState, useEffect } from 'react';
// import { Typography, TextField, Button, Container, Grid, Select, MenuItem, InputLabel, FormControl } from '@mui/material';
// import { useLocation, useNavigate } from 'react-router';

// const AddNotification = () => {
//     const location = useLocation();
//     const navigate = useNavigate();
//     const status = location.state && location.state.status;
//     const handleAddNotification = location.state && location.state.handleAddNotification;

//     const [notification, setNotification] = useState({
//         id: Date.now(), // Generate a unique ID
//         title: '',
//         message: '',
//         time: '',
//         frequency: '',
//         type: ''
//     });

//     useEffect(() => {
//         if (location.state && location.state.notification) {
//             setNotification(location.state.notification);
//         }
//     }, [location.state]);

//     const handleInputChange = (e) => {
//         const { name, value } = e.target;
//         setNotification({ ...notification, [name]: value });
//     };

//     const handleSubmit = (e) => {
//         e.preventDefault();
//         if (status === 'add' && handleAddNotification) {
//             handleAddNotification(notification); // Pass the new notification back to the parent component
//         }
//         navigate('/notification'); // Navigate back to the notification list
//     };

//     return (
//         <div
//             style={{
//                 backgroundColor: '#f5f5f5',
//                 minHeight: '100vh',
//                 marginTop: '60px'
//             }}
//         >
//             <Container
//                 maxWidth="lg"
//                 style={{
//                     backgroundColor: 'white',
//                     padding: 20,
//                     borderRadius: 10
//                 }}
//             >
//                 <Typography variant="h3" align="center" gutterBottom style={{ marginBottom: 20 }}>
//                     Manage Notification
//                 </Typography>
//                 <form onSubmit={handleSubmit}>
//                     <Grid container spacing={3}>
//                         <Grid item xs={12}>
//                             <TextField fullWidth label="Title" name="title" value={notification.title} onChange={handleInputChange} />
//                         </Grid>
//                         <Grid item xs={12}>
//                             <TextField
//                                 fullWidth
//                                 label="Message"
//                                 name="message"
//                                 multiline
//                                 rows={4}
//                                 value={notification.message}
//                                 onChange={handleInputChange}
//                             />
//                         </Grid>
//                         <Grid item xs={12} sm={6}>
//                             <TextField
//                                 fullWidth
//                                 label="Appears in (days)"
//                                 name="time"
//                                 type="number"
//                                 value={notification.time}
//                                 onChange={handleInputChange}
//                             />
//                         </Grid>
//                         <Grid item xs={12} sm={6}>
//                             <TextField
//                                 fullWidth
//                                 label="Repeats (days)"
//                                 name="frequency"
//                                 type="number"
//                                 value={notification.frequency}
//                                 onChange={handleInputChange}
//                             />
//                         </Grid>
//                         <Grid item xs={12}>
//                             <FormControl fullWidth>
//                                 <InputLabel>Type</InputLabel>
//                                 <Select name="type" value={notification.type} onChange={handleInputChange}>
//                                     <MenuItem value="Push">Push</MenuItem>
//                                     <MenuItem value="In-app">In-app</MenuItem>
//                                 </Select>
//                             </FormControl>
//                         </Grid>
//                         <Grid item xs={12} style={{ textAlign: 'right' }}>
//                             <Button variant="contained" color="primary" type="submit">
//                                 {status === 'edit' ? 'Update' : 'Add'}
//                             </Button>
//                         </Grid>
//                     </Grid>
//                 </form>
//             </Container>
//         </div>
//     );
// };

// export default AddNotification;

import React, { useState, useEffect } from 'react';
import { Typography, TextField, Button, Container, Grid, Select, MenuItem, InputLabel, FormControl } from '@mui/material';
import { useLocation, useNavigate } from 'react-router';

const AddNotification = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const status = location.state && location.state.status;
    const handleAddNotification = location.state && location.state.handleAddNotification;

    const [notification, setNotification] = useState({
        id: Date.now(),
        title: '',
        message: '',
        time: '',
        frequency: '',
        type: ''
    });

    useEffect(() => {
        if (location.state && location.state.notification) {
            setNotification(location.state.notification);
        }
    }, [location.state]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNotification({ ...notification, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (status === 'add' && handleAddNotification) {
            handleAddNotification(notification);
        }
        navigate('/notification');
    };

    const isEdit = status === 'edit';

    const readOnlyStyle = {
        backgroundColor: '#f0f0f0', // Light background color
        color: 'rgba(0, 0, 0, 0.6)' // Light text color
    };

    return (
        <div
            style={{
                backgroundColor: '#f5f5f5',
                minHeight: '100vh',
                marginTop: '60px'
            }}
        >
            <Container
                maxWidth="lg"
                style={{
                    backgroundColor: 'white',
                    padding: 20,
                    borderRadius: 10
                }}
            >
                <Typography variant="h3" align="center" gutterBottom style={{ marginBottom: 20 }}>
                    {isEdit ? 'Edit Notification' : 'Add Notification'}
                </Typography>
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Title"
                                name="title"
                                value={notification.title}
                                onChange={handleInputChange}
                                InputProps={{
                                    readOnly: isEdit,
                                    style: isEdit ? readOnlyStyle : {}
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Message"
                                name="message"
                                multiline
                                rows={4}
                                value={notification.message}
                                onChange={handleInputChange}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                label="Appears in (days)"
                                name="time"
                                type="number"
                                value={notification.time}
                                onChange={handleInputChange}
                                InputProps={{
                                    readOnly: isEdit,
                                    style: isEdit ? readOnlyStyle : {}
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                label="Repeats (days)"
                                name="frequency"
                                type="number"
                                value={notification.frequency}
                                onChange={handleInputChange}
                                InputProps={{
                                    readOnly: isEdit,
                                    style: isEdit ? readOnlyStyle : {}
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <InputLabel>Type</InputLabel>
                                <Select
                                    name="type"
                                    value={notification.type}
                                    onChange={handleInputChange}
                                    disabled={isEdit}
                                    style={isEdit ? readOnlyStyle : {}}
                                >
                                    <MenuItem value="Push">Push</MenuItem>
                                    <MenuItem value="In-app">In-app</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} style={{ textAlign: 'right' }}>
                            <Button variant="contained" color="primary" type="submit">
                                {isEdit ? 'Update' : 'Add'}
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </Container>
        </div>
    );
};

export default AddNotification;
