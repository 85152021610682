// // src/LineGraph.js
// import React, { useState } from 'react';
// import { Line } from 'react-chartjs-2';
// import { Chart as ChartJS, LineElement, PointElement, LinearScale, Title, Tooltip, Legend, CategoryScale } from 'chart.js';

// ChartJS.register(LineElement, PointElement, LinearScale, Title, Tooltip, Legend, CategoryScale);

// const LineGraph = ({ initialTimeRange, timeRangeOptions, data, title }) => {
//     const [timeRange, setTimeRange] = useState(initialTimeRange);

//     const selectedData = data[timeRange];

//     const options = {
//         responsive: true,
//         maintainAspectRatio: false,
//         plugins: {
//             legend: {
//                 position: 'bottom'
//             },
//             title: {
//                 display: true,
//                 text: title ? `${title} ` : `Views and Downloads Over Time `
//             }
//         },
//         scales: {
//             x: {
//                 grid: {
//                     display: false
//                 }
//             },
//             y: {
//                 grid: {
//                     display: false
//                 }
//             }
//         }
//     };

//     const chartData = {
//         labels: selectedData.map((item) => item.date),
//         datasets: [
//             {
//                 label: 'Users',
//                 data: selectedData.map((item) => item.views),
//                 borderColor: 'rgba(75, 192, 192, 1)',
//                 backgroundColor: 'rgba(75, 192, 192, 0.2)',
//                 fill: true,
//                 tension: 0.4
//             },
//             {
//                 label: 'Game Users',
//                 data: selectedData.map((item) => item.downloads),
//                 borderColor: 'rgba(153, 102, 255, 1)',
//                 backgroundColor: 'rgba(153, 102, 255, 0.2)',
//                 fill: true,
//                 tension: 0.4
//             }
//         ]
//     };

//     return (
//         <div
//             style={{
//                 position: 'relative',
//                 display: 'flex',
//                 flexDirection: 'column',
//                 alignItems: 'center',
//                 width: '100%',
//                 height: '100%',
//                 backgroundColor: 'white',
//                 padding: '20px',
//                 borderRadius: '10px',
//                 boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
//                 boxSizing: 'border-box'
//             }}
//         >
//             {/* <div
//         style={{
//           position: "absolute",
//           top: "20px",
//           right: "20px",
//         }}
//       >
//         <select
//           value={timeRange}
//           onChange={(e) => setTimeRange(e.target.value)}
//           style={{
//             padding: "10px 20px",
//             cursor: "pointer",
//             borderRadius: "5px",
//             border: "1px solid #ccc",
//             backgroundColor: "#f9f9f9",
//             boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
//           }}
//         >
//           {timeRangeOptions.map((option) => (
//             <option key={option} value={option}>
//               {option.charAt(0).toUpperCase() + option.slice(1)}
//             </option>
//           ))}
//         </select>
//       </div> */}
//             <div style={{ width: '100%', height: '400px', marginTop: '20px' }}>
//                 <Line data={chartData} options={options} />
//             </div>
//         </div>
//     );
// };

// export default LineGraph;

// src/LineGraph.js
import React, { useState } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, LineElement, PointElement, LinearScale, Title, Tooltip, Legend, CategoryScale } from 'chart.js';

ChartJS.register(LineElement, PointElement, LinearScale, Title, Tooltip, Legend, CategoryScale);

const LineGraph = ({ initialTimeRange, timeRangeOptions, data, title }) => {
    const [timeRange, setTimeRange] = useState(initialTimeRange);

    const selectedData = data[timeRange];

    const options = {
        maintainAspectRatio: false,
        plugins: {
            title: {
                display: true,
                text: title || 'Views and Downloads Over Time',
                font: {
                    size: 16,
                    weight: 'bold'
                },
                padding: {
                    top: 5,
                    bottom: 10
                }
            },
            datalabels: {
                align: 'end',
                anchor: 'end',
                offset: 0,
                font: {
                    weight: 'bold',
                    size: 12
                },
                color: '#000',
                formatter: function (value) {
                    return value;
                }
            }
        }
    };

    const chartData = {
        labels: selectedData.map((item) => item.date),
        datasets: [
            {
                label: 'Users',
                data: selectedData.map((item) => item.views),
                borderColor: 'rgba(75, 192, 192, 1)',
                backgroundColor: 'rgba(75, 192, 192, 0.2)',
                fill: true,
                tension: 0.4
            },
            {
                label: 'Game Users',
                data: selectedData.map((item) => item.downloads),
                borderColor: 'rgba(153, 102, 255, 1)',
                backgroundColor: 'rgba(153, 102, 255, 0.2)',
                fill: true,
                tension: 0.4
            },
            {
                label: 'IOS Users',
                data: selectedData.map((item) => item.appleUsers),
                borderColor: 'rgba(255, 99, 132, 1)',
                backgroundColor: 'rgba(255, 99, 132, 0.2)',
                fill: true,
                tension: 0.4
            },
            {
                label: 'Android Users',
                data: selectedData.map((item) => item.androidUsers),
                borderColor: 'rgba(54, 162, 235, 1)',
                backgroundColor: 'rgba(54, 162, 235, 0.2)',
                fill: true,
                tension: 0.4
            },
            {
                label: 'IOS Game Users',
                data: selectedData.map((item) => item.activeAppleUsers),
                borderColor: 'rgba(255, 206, 86, 1)',
                backgroundColor: 'rgba(255, 206, 86, 0.2)',
                fill: true,
                tension: 0.4
            },
            {
                label: 'Android Game Users',
                data: selectedData.map((item) => item.activeAndroidUsers),
                borderColor: 'rgba(75, 192, 192, 1)',
                backgroundColor: 'rgba(75, 192, 192, 0.2)',
                fill: true,
                tension: 0.4
            }
        ]
    };

    return (
        <div
            style={{
                position: 'relative',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: '100%',
                height: '100%',
                backgroundColor: 'white',
                padding: '20px',
                borderRadius: '10px',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                boxSizing: 'border-box'
            }}
        >
            <div
                style={{
                    position: 'absolute',
                    top: '20px',
                    right: '20px'
                }}
            >
                <select
                    value={timeRange}
                    onChange={(e) => setTimeRange(e.target.value)}
                    style={{
                        padding: '10px 20px',
                        cursor: 'pointer',
                        borderRadius: '5px',
                        border: '1px solid #ccc',
                        backgroundColor: '#f9f9f9',
                        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)'
                    }}
                >
                    {timeRangeOptions.map((option) => (
                        <option
                            key={option}
                            value={option}
                            style={{
                                padding: '5px 10px'
                            }}
                        >
                            {option.charAt(0).toUpperCase() + option.slice(1)}
                        </option>
                    ))}
                </select>
            </div>
            <div style={{ width: '100%', height: '400px', marginTop: '20px' }}>
                <Line data={chartData} options={options} />
            </div>
        </div>
    );
};

export default LineGraph;
